import { api } from '@/lib/api'
import { useEffect, useState } from 'react'
import { BookIcon, LoaderCircleIcon, SearchIcon } from 'lucide-react'
import { InView } from 'react-intersection-observer'
import { useQueryState } from 'nuqs'
import { ArchiveCard } from '@/components/ArchiveCard'
import { ArchiveStatus, BrowseSort } from '@/types'
import { SiteFilter } from './components/SiteFilter'
import { Button } from '@/components/ui/button'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { cn } from '@/lib/utils'
import TwitterXIcon from '@/components/TwitterXIcon'
import { Search } from '@/components/Search'
import useUser from '@/hooks/useUser'
import { Authentication } from '@/components/Authentication'

interface Props {
  disableEndlessScroll?: boolean
  pageSize?: number
  hideViewToggle?: boolean
  hideFilters?: boolean
}

export const FEED_SEARCH_PARAMS = { SITE: 's', VIEW: 'v' } as const

export const FEED_VIEWS = {
  RANKED: 'top',
  FEED: 'latest',
  PERSONALIZED: 'personalized',
} as const

export default function FeedPage(props: Props) {
  const { disableEndlessScroll, pageSize, hideViewToggle, hideFilters } = props

  const { data: btcInfo } = api.bitcoin.priceInfo.useQuery()

  const { loggedIn } = useUser()

  const [showSearch, setShowSearch] = useState(false)
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState<ArchiveStatus | undefined>('on-chain')
  const [sort, setSort] = useState<BrowseSort>('fee_desc')
  // const [searchParams, setSearchParams] = useSearchParams()
  // const viewParam =
  //   searchParams.get(FEED_SEARCH_PARAMS.VIEW) || FEED_VIEWS.RANKED
  // const [view, setView] = useState(viewParam)

  // const site = searchParams.get(FEED_SEARCH_PARAMS.SITE) || ''
  const [view, setView] = useQueryState(FEED_SEARCH_PARAMS.VIEW, {
    defaultValue: FEED_VIEWS.RANKED,
  })
  const [site, setSite] = useQueryState(FEED_SEARCH_PARAMS.SITE, {
    defaultValue: '',
  })

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isPending } =
    api.archives.browse.useInfiniteQuery(
      {
        search,
        limit: pageSize,
        status: status || 'all',
        domain: site || undefined,
        personalized: view === FEED_VIEWS.PERSONALIZED,
        sort,
      },
      { getNextPageParam: (lastPage) => lastPage.nextPage },
    )

  useEffect(() => {
    // console.log('setting view', view)
    switch (view) {
      case FEED_VIEWS.RANKED:
        setSort('fee_desc')
        setStatus('on-chain')
        break
      case FEED_VIEWS.FEED:
        setSort('date_desc')
        setStatus('all')
        break
      case FEED_VIEWS.PERSONALIZED:
        setSort('date_desc')
        setStatus('all')
        break
    }
  }, [view])

  const isEmpty =
    !isPending && (!data?.pages.length || !data?.pages[0].archives.length)

  const archives = data?.pages.flatMap((page) => page.archives)
  return (
    <div className={`mx-auto w-full max-w-screen-lg pt-2 sm:pt-8 md:pl-10`}>
      <Tabs defaultValue={view} onValueChange={setView}>
        <div
          className={`flex flex-col items-center justify-between gap-4 transition-opacity duration-500 sm:flex-row sm:gap-2 ${cn(
            { 'invisible opacity-0': hideViewToggle },
          )}`}
        >
          <TabsList className="w-full sm:w-auto">
            <TabsTrigger className="flex-1" value={FEED_VIEWS.RANKED}>
              Top
            </TabsTrigger>
            <TabsTrigger className="flex-1" value={FEED_VIEWS.FEED}>
              Latest
            </TabsTrigger>
            <TabsTrigger className="flex-1" value={FEED_VIEWS.PERSONALIZED}>
              For You
            </TabsTrigger>
          </TabsList>
          {!hideFilters && (
            <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
              <div className="grid w-full grid-cols-2 items-center gap-2 sm:grid-cols-[auto,auto,auto,auto,1fr]">
                <Button
                  variant="ghost"
                  className="hidden sm:flex"
                  size="icon"
                  onClick={() => setShowSearch(!showSearch)}
                >
                  <SearchIcon className="size-5" />
                </Button>
                <Button
                  variant={site === 'x.com' ? 'default' : 'outline'}
                  onClick={() => setSite(site === 'x.com' ? '' : 'x.com')}
                >
                  <TwitterXIcon className="mr-2 h-4 w-4" />
                  X/Twitter
                </Button>
                <Button
                  variant={site === 'gutenberg.org' ? 'default' : 'outline'}
                  onClick={() =>
                    setSite(site === 'gutenberg.org' ? '' : 'gutenberg.org')
                  }
                >
                  <BookIcon className="mr-2 h-4 w-4 flex-shrink-0" />
                  Project Gutenberg
                </Button>
                <SiteFilter site={site} setSite={setSite} status={status} />
                <Button
                  variant="ghost"
                  className="flex sm:hidden"
                  size="icon"
                  onClick={() => setShowSearch(!showSearch)}
                >
                  <SearchIcon className="size-5" />
                </Button>
              </div>
            </div>
          )}
          {showSearch && (
            <div className="mt-4">
              <Search onSearch={setSearch} />
            </div>
          )}
        </div>
        {!loggedIn && view === FEED_VIEWS.PERSONALIZED ? (
          <div className="border-border mt-8 max-w-md rounded-lg border p-4">
            <Authentication view="login" inline />
          </div>
        ) : (
          <>
            <section className="mt-4 flex flex-col gap-2">
              {isPending &&
                new Array(pageSize ?? 12)
                  .fill(0)
                  .map((_, i) => (
                    <div
                      key={i}
                      className="bg-muted mt-2 h-36 w-full animate-pulse rounded-lg"
                    />
                  ))}
              {isEmpty && (
                <div className="mt-6 text-center sm:text-left">
                  No archives found.
                </div>
              )}

              {archives?.map((a, i) => (
                <ArchiveCard
                  key={a._id}
                  archive={a}
                  btcPriceUsd={btcInfo?.price ?? 0}
                />
              ))}
              {!disableEndlessScroll && hasNextPage && (
                <InView
                  as="div"
                  onChange={(inView) => {
                    if (inView) {
                      fetchNextPage()
                    }
                  }}
                />
              )}
            </section>
            {isFetchingNextPage && (
              <div className="mt-6 flex w-full justify-center">
                <LoaderCircleIcon className="animate-spin" />
              </div>
            )}
          </>
        )}
      </Tabs>
    </div>
  )
}
