import { api } from '@/lib/api'
import { formatFileSize } from '@/lib/formatting'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { LoaderCircleIcon } from 'lucide-react'
import { PriceSwitcher } from '@/components/PriceSwitcher'
// import * as Plot from '@observablehq/plot'
// import { useEffect, useRef } from 'react'

export default function StatsPage() {
  const { data: btcInfo } = api.bitcoin.priceInfo.useQuery()
  const { data: stats, isLoading } = api.archives.stats.useQuery()

  if (isLoading) {
    return (
      <div className="mt-6 flex h-full items-center justify-center">
        <LoaderCircleIcon className="h-8 w-8 animate-spin" />
      </div>
    )
  }

  if (!stats) {
    return <div>No stats available</div>
  }

  return (
    <div className="container mx-auto mt-6 py-8">
      <h1 className="mb-6 text-3xl font-bold">Archive Statistics</h1>
      <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <Card>
          <CardHeader>
            <CardTitle>Total Archives</CardTitle>
          </CardHeader>
          <CardContent>
            <p>{stats.totalArchives.toString()}</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>On-Chain Archives</CardTitle>
          </CardHeader>
          <CardContent>
            <p>{stats.onChainArchives.toString()}</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>On-Chain Data</CardTitle>
          </CardHeader>
          <CardContent>
            <p>{formatFileSize(stats.totalOnChainSize)}</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Total Fees</CardTitle>
          </CardHeader>
          <CardContent>
            <PriceSwitcher
              sats={stats.totalFeesSats}
              bitcoinPrice={btcInfo?.price ?? 0}
              className="cursor-pointer"
            />
          </CardContent>
        </Card>
      </div>
      {/* <div className="mt-10">
        <Chart />
      </div> */}
    </div>
  )
}

// function Chart() {
//   const containerRef = useRef<HTMLDivElement>(null)
//   const { data: data } = api.archives.browse.useQuery({
//     limit: 100_000,
//     status: 'on-chain',
//   })

//   useEffect(() => {
//     if (!data) return

//     const plotData = data.archives.map((archive) => ({
//       ...archive,
//       ['sats/byte']: archive.full_text_tx
//         ? archive.full_text_tx.satsPerByte
//         : archive.hash_tx?.satsPerByte,
//       ['date']: Number(
//         archive.full_text_tx
//           ? archive.full_text_tx.blockTimestamp
//           : archive.hash_tx?.blockTimestamp,
//       ),
//       // ['total sats']:
//       //   archive.full_text_tx?.totalSats ?? archive.hash_tx?.totalSats,
//     }))

//     console.log(plotData)
//     const plot = Plot.plot({
//       y: {
//         grid: true,
//         // percent: true,
//       },
//       x: {
//         tickFormat(d) {
//           return new Date(Number(d) * 1000).toLocaleDateString()
//         },
//       },
//       marks: [
//         // Plot.ruleY([0]),
//         Plot.barY(plotData, {
//           x: 'date',
//           y: 'sats/byte',
//           // fill: 'sats/byte',
//           tip: true,
//           channels: { url: 'url', ['file size']: 'fileSize' },
//         }),
//       ],
//     })
//     containerRef.current?.append(plot)

//     return () => {
//       plot.remove()
//     }
//   }, [data])

//   return <div ref={containerRef} />
// }
