import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { api } from './lib/api'
import { httpBatchLink } from '@trpc/client'
import { Routes } from './routes'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { PostHogProvider } from 'posthog-js/react'
import posthog from 'posthog-js'
import { NuqsAdapter } from 'nuqs/adapters/react-router/v6'

import * as Sentry from '@sentry/react'

import { PageView } from './PageView'
import { ThemeProvider } from './components/ThemeProvider'
import { Wallet } from '@canonicxyz/wallet-sdk'
import useUser, { getAuthToken } from './hooks/useUser'
import { Env } from './lib/env'
import useBalance from './hooks/useBalance'
import { getMetatags } from 'backend/src/lib/metatags'
import { Helmet } from 'react-helmet'
import { TooltipProvider } from './components/ui/tooltip'

if (Env.POSTHOG_API_KEY) {
  posthog.init(Env.POSTHOG_API_KEY, {
    capture_pageview: false,
    capture_pageleave: true,
    api_host: 'https://wallet.canonic.xyz/ingest',
  })
}

function Providers({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(() => new QueryClient())
  const [trpcClient] = useState(() => {
    const headers = () => {
      const token = getAuthToken()
      const headers = token ? { token } : {}

      return headers
    }

    return api.createClient({
      links: [httpBatchLink({ headers, url: '/api/trpc' })],
    })
  })

  return (
    <Sentry.ErrorBoundary
      fallback={(error) => (
        <div className="flex h-dvh flex-col items-center justify-center">
          <div className="text-2xl font-bold">An error occurred</div>
          <div className="text-muted-foreground text-sm">
            {error.error instanceof Error
              ? error.error.message
              : 'Unknown error'}
          </div>
        </div>
      )}
    >
      <PostHogProvider client={posthog}>
        <ThemeProvider>
          <NuqsAdapter>
            <BrowserRouter>
              <api.Provider client={trpcClient} queryClient={queryClient}>
                <QueryClientProvider client={queryClient}>
                  <TooltipProvider delayDuration={0}>
                    {children}
                  </TooltipProvider>
                </QueryClientProvider>
              </api.Provider>
              <PageView />
            </BrowserRouter>
          </NuqsAdapter>
        </ThemeProvider>
      </PostHogProvider>
    </Sentry.ErrorBoundary>
  )
}

function App() {
  const { fetchUser, user } = useUser()
  const { fetchBalance } = useBalance()
  const [connectedWallet, setConnectedWallet] = useState(false)

  useEffect(() => {
    fetchUser()
  }, [])

  useEffect(() => {
    if (Wallet.isLoggedIn() && !connectedWallet) {
      Wallet.connectWalletSse(async () => {
        await fetchBalance()
      }, !Env.PROD)

      // Wallet.connectUserSse(async () => {})

      fetchUser()
      fetchBalance()

      setConnectedWallet(true)
    }
  }, [user])

  return (
    <Providers>
      <Metatags />

      <Routes />
    </Providers>
  )
}

function Metatags() {
  const location = useLocation()

  const [metatags, setMetatags] = useState<{
    title: string
    description?: string
  } | null>(null)

  useEffect(() => {
    setMetatags(getMetatags(location.pathname))
  }, [location.pathname])

  if (!metatags) return null

  return (
    <Helmet>
      <title>{metatags.title}</title>
      <meta name="og:title" content={metatags.title} />

      {metatags.description && (
        <meta name="og:description" content={metatags.description} />
      )}
    </Helmet>
  )
}

export default App
