import { AppRoutes } from './routes'
import { Navigate, Outlet } from 'react-router-dom'
import useUser from './hooks/useUser'
import { LoaderCircleIcon } from 'lucide-react'

export function ProtectedLayout() {
  const { loading, loggedIn } = useUser()

  if (!loggedIn) {
    return <Navigate to={AppRoutes.LOGIN} />
  }

  if (loading)
    return (
      <div className="flex flex-1 items-center justify-center">
        <LoaderCircleIcon className="size-6 animate-spin" />
      </div>
    )

  return <Outlet />
}
