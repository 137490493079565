import { PayButton } from '@/components/PayButton'
import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { toast } from '@/components/ui/use-toast'
import useBalance from '@/hooks/useBalance'
import {
  CombinedWalletHistory,
  Ticker,
  Wallet,
  bchaddr,
} from '@canonicxyz/wallet-sdk'
import {
  ArrowDownToLineIcon,
  ArrowRightLeftIcon,
  ArrowUpIcon,
  ChevronFirstIcon,
  ChevronLastIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  CopyIcon,
  EyeIcon,
  KeyRoundIcon,
  LoaderCircleIcon,
} from 'lucide-react'
import { ComponentProps, useMemo, useRef, useState } from 'react'

import { Badge } from '@/components/ui/badge'
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import useUser from '@/hooks/useUser'
import { api } from '@/lib/api'
import { Env } from '@/lib/env'
import { formatCurrency } from '@/lib/formatting'
import { cn, getTxUrl } from '@/lib/utils'
import { AppRoutes } from '@/routes'
import { IArchive, IUpvote } from '@/types'
import { useLocalStorage } from '@uidotdev/usehooks'
import { useQueryState } from 'nuqs'
import { useNavigate } from 'react-router-dom'

export const WALLET_TABS = {
  SEND: 'send',
  RECEIVE: 'receive',
  HISTORY: 'history',
} as const

const DEFAULT_WALLET_TAB = WALLET_TABS.SEND

export const WALLET_SEARCH_PARAMS = {
  TAB: 't',
} as const

export default function WalletPage() {
  const [showSeedPhrase, setShowSeedPhrase] = useState(false)

  const [amount, setAmount] = useState('')
  const [recipientAddress, setRecipientAddress] = useState('')
  const [currency, setCurrency] = useState<'USD' | 'BITCOIN'>('USD')
  const [ticker, setTicker] = useState<Ticker>('BTC')
  const [addressType, setAddressType] = useState<'normal' | 'cashaddress'>(
    'normal',
  )

  const [tab, setTab] = useQueryState(WALLET_SEARCH_PARAMS.TAB, {
    defaultValue: DEFAULT_WALLET_TAB,
  })

  const { balance, allUtxos, history } = useBalance()
  const { user } = useUser()

  const { addRecentRecipient } = useRecentRecipients()

  const receiveAddress = useMemo(() => Wallet.getChangeAddress(), [])
  const receiveAddressBCH = useMemo(
    () => bchaddr.toCashAddress(receiveAddress),
    [receiveAddress],
  )

  const address =
    addressType === 'cashaddress' ? receiveAddressBCH : receiveAddress

  const onSend: ComponentProps<typeof PayButton>['onSuccess'] = async (
    _args,
  ) => {
    if (recipientAddress.includes('@')) {
      addRecentRecipient(recipientAddress)
    }

    setAmount('')
    setRecipientAddress('')
  }

  const copyAddress = () => {
    navigator.clipboard.writeText(address)
    toast({ title: 'Address Copied' })
  }

  if (!user) return null

  const availableTickers =
    allUtxos &&
    Object.values(allUtxos)
      .filter((utxos) =>
        utxos.total_usd > 0
          ? Env.PROD
            ? utxos.ticker !== 'SIGNET'
            : true
          : false,
      )
      .sort((a) => (a.ticker === 'BTC' ? -1 : 1))

  return (
    <div className="mx-auto mt-10 w-full max-w-4xl space-y-6 p-0">
      <Card className="bg-gradient-to-br from-indigo-600 to-indigo-300 dark:from-indigo-600/70 dark:to-indigo-300/70">
        <CardHeader>
          <CardTitle className="text-white">Wallet Balance</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center justify-between gap-2">
            <div className="space-y-1">
              <div className="text-4xl font-bold text-white">{balance}</div>
              <div className="flex flex-wrap items-center divide-x divide-white/50">
                {availableTickers &&
                  [...availableTickers] // Create a copy so the sort doesn't mutate the original
                    .sort((a, b) => b.total_usd - a.total_usd)
                    .map((utxos, i) => (
                      <div
                        key={utxos.ticker}
                        className={`mt-1 pr-4 text-sm text-white/80 ${cn({ 'pl-4': i !== 0 })}`}
                      >
                        {formatCurrency(utxos.total_usd)} {utxos.ticker}
                      </div>
                    ))}
              </div>
            </div>
            <Dialog>
              <DialogTrigger asChild>
                <button className="self-end">
                  <KeyRoundIcon className="size-5 stroke-white" />
                </button>
              </DialogTrigger>
              <DialogContent
                className="max-w-screen-md"
                aria-describedby={undefined}
              >
                <DialogHeader>
                  <DialogTitle>Wallet Details</DialogTitle>
                </DialogHeader>
                <div className="space-y-4 text-sm">
                  {Object.entries(user).map(([key, value]) => (
                    <div key={key}>
                      <div className="font-medium capitalize">{key}</div>
                      <div className="text-muted-foreground break-all text-sm">
                        {value}
                      </div>
                    </div>
                  ))}
                  <div>
                    <div className="font-medium capitalize">Seed phrase</div>
                    <div className="flex items-center gap-2">
                      <div
                        className={`text-muted-foreground inline-block break-all text-sm ${cn({ 'blur-sm': !showSeedPhrase })}`}
                      >
                        {Wallet.getSeed()}
                      </div>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => setShowSeedPhrase(!showSeedPhrase)}
                      >
                        <EyeIcon className="size-4" />
                      </Button>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </CardContent>
      </Card>

      <Tabs defaultValue={tab} className="w-full" onValueChange={setTab}>
        <TabsList className="grid w-full grid-cols-3">
          <TabsTrigger value={WALLET_TABS.SEND}>Send</TabsTrigger>
          <TabsTrigger
            value={WALLET_TABS.RECEIVE}
            className="border-border border-l border-r"
          >
            Receive
          </TabsTrigger>
          <TabsTrigger value={WALLET_TABS.HISTORY}>History</TabsTrigger>
        </TabsList>

        <TabsContent value={WALLET_TABS.SEND}>
          <Card className="border-0 shadow-none sm:border sm:shadow-sm">
            <CardHeader className="px-0 sm:p-6">
              <CardTitle>Send Bitcoin</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4 px-0 sm:px-6">
              <div className="grid grid-cols-[1fr,6rem] gap-2">
                <Label htmlFor="amount">
                  Amount ({currency === 'BITCOIN' ? ticker : 'USD'}){' '}
                </Label>
                <Label>Coin</Label>
                <Input
                  id="amount"
                  type="number"
                  placeholder="0.00"
                  step="0.00001"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                />
                <Select
                  value={ticker}
                  onValueChange={(value) => {
                    setTicker(value as Ticker)
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select Currency" />
                  </SelectTrigger>
                  <SelectContent>
                    {availableTickers &&
                      availableTickers.map((utxos) => (
                        <SelectItem key={utxos.ticker} value={utxos.ticker}>
                          {utxos.ticker}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
                <button
                  onClick={() =>
                    setCurrency(currency === 'BITCOIN' ? 'USD' : 'BITCOIN')
                  }
                  className="text-muted-foreground flex items-center gap-1 text-xs"
                >
                  <ArrowRightLeftIcon className="size-4" />
                  Switch to {currency === 'BITCOIN' ? 'USD' : ticker} input
                </button>
              </div>
              <div className="space-y-2">
                <Label htmlFor="address">Recipient Address</Label>
                <RecipientAddressInput
                  address={recipientAddress}
                  setAddress={setRecipientAddress}
                />
              </div>
            </CardContent>
            <CardFooter className="px-0 sm:px-6">
              <PayButton
                label="Send"
                ticker={ticker}
                onSuccess={onSend}
                disabled={!amount || !recipientAddress}
                className="w-full sm:w-auto"
                containerClassName="w-full sm:w-auto mx-auto"
                outputs={[
                  {
                    addressOrPaymail: recipientAddress,
                    amount: amount ? Number(amount) : 0,
                    currency: currency === 'BITCOIN' ? ticker : 'USD',
                  },
                ]}
              />
            </CardFooter>
          </Card>
        </TabsContent>

        <TabsContent value={WALLET_TABS.RECEIVE}>
          <Card className="border-0 shadow-none sm:border sm:shadow-sm">
            <CardHeader className="px-0 sm:p-6">
              <CardTitle>Receive Bitcoin</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4 px-0 sm:px-6">
              <div className="flex items-center gap-4">
                <div className="flex-1">
                  <Label>Your Bitcoin Address</Label>
                  <div className="mt-2 flex items-center gap-2">
                    <Input value={address} readOnly disabled />
                    <Button variant="outline" size="icon" onClick={copyAddress}>
                      <CopyIcon className="h-4 w-4" />
                    </Button>
                  </div>
                  <button
                    onClick={() =>
                      setAddressType(
                        addressType === 'cashaddress'
                          ? 'normal'
                          : 'cashaddress',
                      )
                    }
                    className="text-muted-foreground mt-2 flex items-center gap-1 text-xs"
                  >
                    <ArrowRightLeftIcon className="size-4" />
                    {addressType === 'cashaddress'
                      ? 'Show normal address'
                      : 'Show cash address format (BCH)'}
                  </button>
                </div>
              </div>
              <div className="flex justify-center p-2">
                <img
                  src={`https://quickchart.io/qr?text=${address}&size=240`}
                  alt="Wallet QR Code"
                  className="h-60 w-60"
                />
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value={WALLET_TABS.HISTORY}>
          <Card className="border-0 shadow-none sm:border sm:shadow-sm">
            <CardHeader className="px-0 sm:p-6">
              <CardTitle>Transaction History</CardTitle>
            </CardHeader>
            <CardContent className="px-0 sm:px-6">
              <PaginatedTransactions history={history ?? []} />
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  )
}

const PAGE_SIZE = 10

const getArchiveUrl = (archive: IArchive | string) => {
  return typeof archive === 'string' ? archive : archive.url
}

function PaginatedTransactions({
  history,
}: {
  history: CombinedWalletHistory
}) {
  const [page, setPage] = useState(1)

  const { data: btcInfo, isLoading: loadingBtcInfo } =
    api.bitcoin.priceInfo.useQuery()
  const { data: upvotes, isLoading: loadingUpvotes } =
    api.user.upvotes.useQuery()

  const navigate = useNavigate()

  let transactions: (CombinedWalletHistory[number] & { upvote?: IUpvote })[] =
    history.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
  transactions = transactions.map((t) => {
    const upvote = upvotes?.find((u) => u.txid === t.txid)
    return {
      ...t,
      upvote,
    }
  })

  const hasNextPage = page * PAGE_SIZE < history.length
  const totalPages = Math.ceil(history.length / PAGE_SIZE)
  const hasPreviousPage = page > 1

  const loading = loadingBtcInfo || loadingUpvotes

  return (
    <div className="space-y-6">
      {loading ? (
        <LoaderCircleIcon className="size-4 animate-spin" />
      ) : (
        transactions.map((tx) => (
          <div key={tx.txid} className="relative">
            <a
              href={getTxUrl(tx.txid, tx.ticker)}
              rel="noreferrer"
              target="_blank"
              className="block"
            >
              <div className="flex items-center justify-between rounded-lg border p-4">
                <div className="flex items-center gap-4 overflow-hidden">
                  {tx.type === 'received' ? (
                    <ArrowDownToLineIcon className="size-5 flex-shrink-0 text-green-500" />
                  ) : (
                    <ArrowUpIcon
                      className={`size-5 flex-shrink-0 ${tx.upvote ? 'text-purple-500' : 'text-orange-500'}`}
                    />
                  )}
                  <div className="min-w-0">
                    <div className="font-medium">
                      {tx.upvote ? (
                        `Upvoted for ${formatCurrency(
                          Wallet.formatDollars(
                            tx.upvote.pending?.fee_sats ??
                              tx.upvote.pow?.tx_fee_sats ??
                              0,
                            btcInfo?.price ?? 0,
                          ).number + tx.amount_usd,
                        )}`
                      ) : (
                        <>
                          {tx.type === 'received' ? 'Received' : 'Sent'}{' '}
                          {formatCurrency(Number(tx.amount_usd))}
                        </>
                      )}
                    </div>

                    {tx.upvote?.archive_id && (
                      <button
                        onClick={(e) => {
                          if (!tx.upvote?.archive_id) return
                          e.preventDefault()
                          navigate(
                            AppRoutes.buildArchiveRoute(
                              getArchiveUrl(tx.upvote.archive_id),
                            ),
                          )
                        }}
                        className="text-muted-foreground w-full truncate text-sm underline-offset-4 hover:underline"
                      >
                        {typeof tx.upvote.archive_id === 'string'
                          ? new URL(tx.upvote.archive_id).hostname
                          : tx.upvote.archive_id.title ||
                            new URL(tx.upvote.archive_id.url).hostname}
                      </button>
                    )}
                  </div>
                </div>
                <div className="text-muted-foreground flex flex-shrink-0 items-center gap-2 text-sm">
                  <ClockIcon className="h-4 w-4" />
                  {new Date(tx.time * 1000).toLocaleString('en-US', {
                    dateStyle: 'short',
                    timeStyle: 'short',
                  })}
                </div>
              </div>
            </a>
            <div className="absolute inset-x-0 top-0 flex -translate-y-1/2 justify-end pr-4">
              <Badge variant="secondary">{tx.ticker}</Badge>
            </div>
          </div>
        ))
      )}
      <div className="flex items-center justify-center gap-2">
        <button
          onClick={() => setPage(1)}
          disabled={page === 1}
          className="disabled:opacity-50"
        >
          <ChevronFirstIcon className="size-5" />
        </button>
        <button
          onClick={() => setPage(page - 1)}
          disabled={!hasPreviousPage}
          className="disabled:opacity-50"
        >
          <ChevronLeftIcon className="size-5" />
        </button>
        <div className="text-center text-sm">
          Page {page} of {totalPages}
        </div>
        <button
          onClick={() => setPage(page + 1)}
          disabled={!hasNextPage}
          className="disabled:opacity-50"
        >
          <ChevronRightIcon className="size-5" />
        </button>
        <button
          onClick={() => setPage(totalPages)}
          disabled={page === totalPages}
          className="disabled:opacity-50"
        >
          <ChevronLastIcon className="size-5" />
        </button>
      </div>
    </div>
  )
}

function useRecentRecipients() {
  const [recipients, setRecipients] = useLocalStorage<string[]>(
    'recentRecipients',
    [],
  )

  const addRecentRecipient = (recipient: string) => {
    const existing = [...recipients]

    // Move it to the top of the list
    if (existing.includes(recipient)) {
      existing.splice(existing.indexOf(recipient), 1)
    }

    existing.unshift(recipient)

    setRecipients(existing)
  }

  return { recentRecipients: recipients, addRecentRecipient }
}

function RecipientAddressInput({
  address,
  setAddress,
}: {
  address: string
  setAddress: (address: string) => void
}) {
  const [showRecents, setShowRecents] = useState(false)
  const { recentRecipients } = useRecentRecipients()
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <Popover
      open={showRecents && !!recentRecipients.length}
      onOpenChange={setShowRecents}
    >
      <PopoverTrigger asChild>
        <div>
          <Input
            ref={inputRef}
            value={address}
            id="address"
            placeholder="Type an address or paymail"
            onChange={(e) => setAddress(e.target.value)}
            autoComplete="off"
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        sideOffset={4}
        onOpenAutoFocus={(e) => e.preventDefault()}
        className="p-0"
      >
        <Command shouldFilter={false}>
          <CommandList>
            <CommandGroup heading="Recents">
              {recentRecipients.slice(0, 10).map((recipient) => (
                <CommandItem
                  key={recipient}
                  value={recipient}
                  onSelect={(v) => {
                    setAddress(v)
                    inputRef.current?.focus()
                  }}
                >
                  {recipient}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
