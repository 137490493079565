import useUser from '@/hooks/useUser'
import { AppRoutes } from '@/routes'
import { PlusIcon } from 'lucide-react'
import { ComponentProps, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CreateArchiveForm } from './CreateArchiveForm'
import { ArchiveInterstitial } from './ArchiveInterstitial'
import { Button } from './ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog'
import { Separator } from './ui/separator'

interface Props {
  buttonLabel?: string
  hideTrigger?: boolean
  open?: boolean
  defaultUrl?: string
  buttonVariant?: ComponentProps<typeof Button>['variant']
  onClose?: () => void
}

export function NewArchiveModal(props: Props) {
  const {
    buttonLabel,
    hideTrigger,
    defaultUrl,
    onClose,
    buttonVariant = 'outline',
  } = props
  const { loggedIn } = useUser()

  const [open, setOpen] = useState(props.open ?? false)

  const navigate = useNavigate()

  const [showInterstitial, setShowInterstitial] = useState(false)

  const [interruptedAction, setInterruptedAction] = useState<
    (() => () => void) | null
  >(null)

  const goToNewArchive = () => {
    navigate(AppRoutes.NEW_ARCHIVE)
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open)
        !open && onClose?.()
      }}
    >
      {!hideTrigger && (
        <DialogTrigger asChild>
          <Button variant={buttonVariant} size={buttonLabel ? 'sm' : 'icon'}>
            <PlusIcon className="size-4" />
            {buttonLabel && buttonLabel}
          </Button>
        </DialogTrigger>
      )}
      <DialogContent aria-describedby={undefined}>
        <DialogHeader>
          <DialogTitle>Create Archive</DialogTitle>
        </DialogHeader>
        {showInterstitial ? (
          <ArchiveInterstitial
            onContinue={() => {
              interruptedAction?.()
              setShowInterstitial(false)
            }}
          />
        ) : (
          <div className="flex flex-col gap-2">
            <CreateArchiveForm
              onSuccess={() => setOpen(false)}
              interrupt={loggedIn ? undefined : () => setShowInterstitial(true)}
              setInterruptedAction={setInterruptedAction}
              defaultUrl={defaultUrl}
            />

            <Separator className="my-6" />
            <Button variant="outline" onClick={goToNewArchive}>
              or create your own
              <PlusIcon className="h-4 w-4" />
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}
