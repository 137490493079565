import { ArchiveCard } from '@/components/ArchiveCard'
import { Authentication } from '@/components/Authentication'
import { EditProfileForm } from '@/components/EditProfileForm'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { toast } from '@/components/ui/use-toast'
import useUser from '@/hooks/useUser'
import { api } from '@/lib/api'
import { compactNumber, formatDateLong } from '@/lib/formatting'
import { IProfile } from '@/types'
import { format } from 'date-fns'
import {
  CalendarIcon,
  LoaderCircleIcon,
  PencilIcon,
  UserMinusIcon,
  UserPlusIcon,
  UsersIcon,
} from 'lucide-react'
import { useQueryState } from 'nuqs'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { InView } from 'react-intersection-observer'
import { Link, useNavigate, useParams } from 'react-router-dom'

export const PROFILE_TABS = {
  FEED: 'feed',
  FOLLOWERS: 'followers',
  FOLLOWING: 'following',
}

export const PROFILE_SEARCH_PARAMS = { TAB: 't' } as const

const CouldNotFindProfile = () => {
  return (
    <div className="flex flex-1 items-center justify-center">
      <div>Could not find profile</div>
    </div>
  )
}

export default function ProfilePage() {
  const { username } = useParams<{ username: string }>()

  const [showLogin, setShowLogin] = useState(false)

  const navigate = useNavigate()

  const { loading: loadingUser, loggedIn, user } = useUser()

  const [tab, setTab] = useQueryState(PROFILE_SEARCH_PARAMS.TAB, {
    defaultValue: PROFILE_TABS.FEED,
  })

  const { data: btcInfo } = api.bitcoin.priceInfo.useQuery()
  const { refetch: refetchUserProfile } = api.user.profile.useQuery(undefined, {
    enabled: loggedIn,
  })
  const {
    data: profile,
    isLoading,
    refetch: refetchProfile,
  } = api.profiles.get.useQuery({
    username: username || '',
  })

  const {
    data: archiveData,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isPending,
  } = api.archives.browse.useInfiniteQuery(
    {
      user_pubkey: profile?.user_pubkey || '',
      status: 'all',
    },
    { enabled: !!profile, getNextPageParam: (lastPage) => lastPage.nextPage },
  )

  const {
    data: followersData,
    refetch: refetchFollowers,
    hasNextPage: hasNextPageFollowers,
    fetchNextPage: fetchNextPageFollowers,
    isFetchingNextPage: isFetchingNextPageFollowers,
    isPending: loadingFollowers,
  } = api.profiles.followers.useInfiniteQuery(
    {
      username: username || '',
    },
    { getNextPageParam: (lastPage) => lastPage.nextPage },
  )

  const {
    data: following,
    refetch: refetchFollowing,
    isLoading: loadingFollowing,
  } = api.profiles.following.useQuery({
    username: username || '',
  })

  const {
    data: userFollowing,
    refetch: refetchUserFollowing,
    isLoading: loadingUserFollowing,
  } = api.user.following.useQuery(undefined, {
    enabled: loggedIn,
  })

  const followToggleMutation = api.profiles.followToggle.useMutation({
    onError: (error) => {
      toast({
        title: 'Error',
        description: error.message,
        variant: 'destructive',
      })
    },
    onSuccess: ({ follow }) => {
      if (follow) {
        toast({
          title: 'Followed',
        })
      } else {
        toast({
          title: 'Unfollowed',
        })
      }

      refetchUserFollowing()
      refetchFollowers()
      refetchFollowing()
    },
  })

  const loading =
    isLoading || loadingUser || loadingUserFollowing || loadingFollowers

  const isProfileOwner = loggedIn && user?.pubKey === profile?.user_pubkey

  const [editModalOpen, setEditModalOpen] = useState(false)

  if (!username) return <CouldNotFindProfile />

  if (loading)
    return (
      <div className="flex flex-1 items-center justify-center">
        <LoaderCircleIcon className="size-6 animate-spin" />
      </div>
    )

  if (!profile) return <CouldNotFindProfile />

  const isEmpty =
    !isPending &&
    (!archiveData?.pages.length || !archiveData?.pages[0].archives.length)

  const archives = archiveData?.pages.flatMap((page) => page.archives)

  const isFollowing = userFollowing?.some(
    (follow) => follow.following === profile.user_pubkey,
  )

  const followers = followersData?.pages.flatMap((page) => page.followers)

  const onFollowClick = () => {
    if (!loggedIn) {
      setShowLogin(true)
    } else {
      followToggleMutation.mutate({ username: profile.username })
    }
  }

  return (
    <div className="mx-auto mt-10 w-full max-w-screen-lg sm:mt-32">
      <Helmet>
        <title>{`@${username} on Ark`}</title>
        <meta property="og:title" content={`@${username} on Ark`} />
        <meta property="og:description" content={profile.bio} />
      </Helmet>
      <Dialog open={showLogin} onOpenChange={setShowLogin}>
        <DialogContent aria-describedby={undefined}>
          <DialogHeader>
            <DialogTitle>Login to follow {profile.name}</DialogTitle>
          </DialogHeader>
          <Authentication
            inline
            view="login"
            hideHeader
            onSuccess={() => {
              setShowLogin(false)
            }}
          />
        </DialogContent>
      </Dialog>
      <section className="mb-8 flex flex-col sm:items-center">
        <div className="flex flex-col items-start gap-6 md:flex-row">
          <Avatar className="h-24 w-24 rounded-full border-4 border-white shadow-md md:h-32 md:w-32 dark:border-slate-800">
            <AvatarImage
              src={profile.image}
              alt={profile.name}
              className="object-cover"
            />
            <AvatarFallback>
              {profile.name
                .split(' ')
                .map((n) => n[0])
                .join('')}
            </AvatarFallback>
          </Avatar>

          <div className="flex-1 space-y-4">
            <div>
              <h1 className="text-2xl font-bold md:text-3xl">{profile.name}</h1>
              <div className="text-muted-foreground mt-0.5 text-base">
                @{profile.username}
              </div>
            </div>

            <p className="text-muted-foreground max-w-2xl">{profile.bio}</p>

            <div className="flex flex-wrap items-center gap-1">
              <Badge variant="secondary" className="rounded-full px-3">
                <UsersIcon className="mr-1 h-3.5 w-3.5" />
                <span>
                  {compactNumber(followers?.length ?? 0)} follower
                  {followers?.length === 1 ? '' : 's'}
                </span>
              </Badge>
              <Badge variant="secondary" className="rounded-full px-3">
                <CalendarIcon className="mr-1 h-3.5 w-3.5" />
                <span>Joined in {format(profile.created_at, 'yyyy')}</span>
              </Badge>
              <div className="mt-10 sm:ml-5 sm:mt-0" />

              {isProfileOwner ? (
                <Dialog open={editModalOpen} onOpenChange={setEditModalOpen}>
                  <DialogTrigger asChild>
                    <Button variant="outline">
                      <PencilIcon className="mr-2 h-4 w-4" />
                      Edit Profile
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Edit Profile</DialogTitle>
                      <DialogDescription>
                        Make changes to your profile here.
                      </DialogDescription>
                    </DialogHeader>
                    <EditProfileForm
                      onSuccess={async (updatedProfile) => {
                        setEditModalOpen(false)
                        await refetchProfile()
                        await refetchUserProfile()
                        if (updatedProfile.username !== profile.username) {
                          navigate(`/profile/${updatedProfile.username}`)
                        }
                      }}
                    />
                  </DialogContent>
                </Dialog>
              ) : (
                <Button
                  onClick={onFollowClick}
                  disabled={followToggleMutation.isPending}
                >
                  {followToggleMutation.isPending ? (
                    <>
                      <LoaderCircleIcon className="mr-2 size-4 animate-spin" />
                      {isFollowing ? 'Unfollow' : 'Follow'}
                    </>
                  ) : isFollowing && loggedIn ? (
                    <>
                      <UserMinusIcon className="mr-2 size-4" /> Unfollow
                    </>
                  ) : (
                    <>
                      <UserPlusIcon className="mr-2 size-4" /> Follow
                    </>
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="mt-10 sm:mt-32">
        <Tabs defaultValue={tab} onValueChange={setTab}>
          <div className="flex w-full justify-center sm:w-auto">
            <TabsList className="w-full sm:max-w-sm">
              <TabsTrigger value={PROFILE_TABS.FEED} className="flex-1">
                Feed
              </TabsTrigger>
              <TabsTrigger value={PROFILE_TABS.FOLLOWERS} className="flex-1">
                Followers
              </TabsTrigger>

              <TabsTrigger value={PROFILE_TABS.FOLLOWING} className="flex-1">
                Following
              </TabsTrigger>
            </TabsList>
          </div>

          <TabsContent value={PROFILE_TABS.FEED}>
            <div className="mx-auto flex w-full max-w-screen-md flex-col gap-6">
              {isPending &&
                new Array(12)
                  .fill(0)
                  .map((_, i) => (
                    <div
                      key={i}
                      className="bg-muted mt-2 h-36 w-full animate-pulse rounded-lg"
                    />
                  ))}
              {isEmpty && (
                <div className="mt-6 text-center sm:text-left">
                  No archives found.
                </div>
              )}
              {archives?.map((archive) => (
                <ArchiveCard
                  key={archive._id}
                  archive={archive}
                  btcPriceUsd={btcInfo?.price ?? 0}
                />
              ))}
              {hasNextPage && (
                <InView
                  as="div"
                  onChange={(inView) => {
                    if (inView) {
                      fetchNextPage()
                    }
                  }}
                />
              )}
              {isFetchingNextPage && (
                <div className="mt-6 flex w-full justify-center">
                  <LoaderCircleIcon className="animate-spin" />
                </div>
              )}
            </div>
          </TabsContent>
          <TabsContent value={PROFILE_TABS.FOLLOWERS}>
            <div className="mt-6 flex justify-center">
              <div className="flex max-w-lg flex-col gap-6">
                {loadingFollowers &&
                  new Array(12)
                    .fill(0)
                    .map((_, i) => (
                      <div
                        key={i}
                        className="bg-muted h-12 w-full animate-pulse rounded-lg"
                      />
                    ))}
                {!loadingFollowers && followers?.length === 0 && (
                  <div className="text-center">
                    This profile does not have any followers yet.
                  </div>
                )}

                {followers?.map((follower) => (
                  <ProfileLink
                    key={follower._id}
                    profile={follower.follower}
                    date={follower.created_at}
                  />
                ))}
                {hasNextPageFollowers && (
                  <InView
                    as="div"
                    onChange={(inView) => {
                      if (inView) {
                        fetchNextPageFollowers()
                      }
                    }}
                  />
                )}
                {isFetchingNextPageFollowers && (
                  <div className="mt-6 flex w-full justify-center">
                    <LoaderCircleIcon className="animate-spin" />
                  </div>
                )}
              </div>
            </div>
          </TabsContent>
          <TabsContent value={PROFILE_TABS.FOLLOWING}>
            <div className="mt-6 flex justify-center">
              <div className="flex max-w-lg flex-col gap-6">
                {!loadingFollowing && !following?.length && (
                  <div className="text-center">
                    This profile does not follow anyone yet.
                  </div>
                )}
                {following?.map((following) => (
                  <ProfileLink
                    key={following._id}
                    profile={following.following}
                    date={following.created_at}
                  />
                ))}
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </section>
    </div>
  )
}

const ProfileLink = ({
  profile,
  date,
}: {
  profile: IProfile | string
  date: string
}) => {
  if (typeof profile === 'string') return null

  return (
    <Link
      to={`/profile/${profile.username}`}
      className="flex items-center gap-3 self-start"
    >
      <Avatar>
        <AvatarImage src={profile.image} className="object-cover" />
        <AvatarFallback>
          {profile.name
            .split(' ')
            .map((n) => n[0])
            .join('')}
        </AvatarFallback>
      </Avatar>
      <div className="flex flex-col">
        <div className="flex items-center gap-1 font-semibold">
          {profile.name}{' '}
          <div className="text-muted-foreground text-sm font-normal">
            @{profile.username}
          </div>
        </div>
        {profile.bio && <p className="mt-2 line-clamp-2">{profile.bio}</p>}
        <Badge variant="secondary" className="mt-3 self-start">
          Since {formatDateLong(+new Date(date))}
        </Badge>
      </div>
    </Link>
  )
}
