import { LoaderCircleIcon, LogOutIcon, MenuIcon } from 'lucide-react'
import { Suspense, useEffect, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { AudioPlayerCore } from './components/audio/AudioPlayerCore'
import { GlobalAudioPlayer } from './components/audio/GlobalAudioPlayer'
import { EditProfileForm } from './components/EditProfileForm'
import { NewArchiveModal } from './components/NewArchiveModal'
import { ProfileLink } from './components/ProfileLink'
import { ThemeToggle } from './components/ThemeToggle'
import { Button, buttonVariants } from './components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from './components/ui/dialog'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from './components/ui/sheet'
import { Toaster } from './components/ui/toaster'
import useUser from './hooks/useUser'
import { api } from './lib/api'
import { cn } from './lib/utils'
import { AppRoutes } from './routes'

// const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent)
// const isChrome = !!window.chrome

export function Layout() {
  const { logout, loggedIn, user } = useUser()

  const {
    data: profile,
    refetch: refetchProfile,
    isLoading: loadingProfile,
  } = api.user.profile.useQuery(undefined, { enabled: loggedIn })

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const { pathname } = useLocation()

  const isHomePage = pathname === AppRoutes.HOME

  const showCompleteProfileModal =
    loggedIn && !loadingProfile
      ? profile === null && pathname !== AppRoutes.NEW_PROFILE
      : false

  useEffect(() => {
    setMobileMenuOpen(false)
  }, [pathname])

  return (
    <div className="relative flex min-h-dvh w-full flex-col">
      <Dialog open={showCompleteProfileModal} onOpenChange={() => {}}>
        <DialogContent hideCloseButton>
          <DialogHeader>
            <DialogTitle>Complete Your Profile</DialogTitle>
            <DialogDescription>
              Please complete your profile before continuing.
            </DialogDescription>
          </DialogHeader>
          <EditProfileForm
            onSuccess={() => {
              refetchProfile()
            }}
          />
        </DialogContent>
      </Dialog>
      <div
        className={`border-border w-full ${cn({ 'border-b': !isHomePage })}`}
      >
        <header className="flex flex-row items-center justify-between gap-2 px-6 py-2 md:container">
          <Link to="/" className="flex items-center space-x-2">
            <img
              src="/logo-black.png"
              alt="Ark Logo"
              width={64}
              className="w-16 dark:hidden"
            />
            <img
              src="/logo-white.png"
              alt="Ark Logo"
              width={64}
              className="hidden w-16 dark:block"
            />
          </Link>
          {!isHomePage && (
            <div className="ml-2">
              <NewArchiveModal buttonLabel="Create" />
            </div>
          )}
          <div className="flex-1" />
          <div className="flex items-center gap-2 sm:hidden">
            {/* Mobile menu */}
            <Sheet open={mobileMenuOpen} onOpenChange={setMobileMenuOpen}>
              <SheetTrigger>
                <MenuIcon />
              </SheetTrigger>

              <SheetContent aria-describedby={undefined}>
                <SheetHeader>
                  <SheetTitle className="sr-only">Menu</SheetTitle>
                </SheetHeader>
                <div className="mt-3 flex h-full w-full flex-col gap-6">
                  {loggedIn ? (
                    <>
                      <Link
                        to={AppRoutes.WALLET}
                        className={`flex items-center gap-2`}
                      >
                        <img
                          src={user?.image}
                          className="size-10 rounded-full"
                        />{' '}
                        Wallet
                      </Link>
                      <Link
                        to={
                          profile
                            ? AppRoutes.buildProfileRoute(profile.username)
                            : AppRoutes.NEW_PROFILE
                        }
                        className="block"
                      >
                        Profile
                      </Link>
                    </>
                  ) : null}
                  {loggedIn && (
                    <Link to={AppRoutes.SAVED} className="block">
                      Saved
                    </Link>
                  )}
                  <Link to={AppRoutes.FEED} className="block">
                    Browse
                  </Link>
                  <NewArchiveModal buttonLabel="Create" buttonVariant="theme" />
                  <div className="flex flex-1 flex-col justify-end">
                    {!loggedIn ? (
                      <Link
                        to={AppRoutes.LOGIN}
                        className={buttonVariants({ variant: 'outline' })}
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        Login
                      </Link>
                    ) : (
                      <Button variant="outline" onClick={logout}>
                        Logout <LogOutIcon className="size-4" />
                      </Button>
                    )}
                  </div>
                </div>
              </SheetContent>
            </Sheet>
          </div>
          <div className="hidden items-center justify-center gap-8 sm:flex sm:justify-start">
            <Link to={AppRoutes.FEED} className="text-sm">
              Browse
            </Link>
            {loggedIn && (
              <Link to={AppRoutes.SAVED} className="text-sm">
                Saved
              </Link>
            )}
            <ProfileLink />
          </div>
        </header>
      </div>

      <main
        className={`flex flex-1 flex-col pb-12 ${cn({
          'px-4 sm:px-6': !isHomePage,
        })}`}
      >
        <Suspense
          fallback={
            <div className="flex flex-1 items-center justify-center">
              <LoaderCircleIcon className="size-5 animate-spin" />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </main>

      {/* Footer */}
      <div className="border-border border-t">
        <footer className="p-4 py-10 md:container">
          <div className="flex flex-col items-center gap-4 md:flex-row md:justify-between">
            <p className="text-muted-foreground text-sm">
              &copy; {new Date().getFullYear()} Ark. All rights reserved.
            </p>
            <nav className="flex items-center gap-4">
              <Link
                to={AppRoutes.PRIVACY_POLICY}
                className="text-muted-foreground text-sm hover:underline"
              >
                Privacy Policy
              </Link>
              <Link
                to={AppRoutes.STATS}
                className="text-muted-foreground text-sm hover:underline"
              >
                Stats
              </Link>
              <Link
                to={AppRoutes.ABOUT}
                className="text-muted-foreground text-sm hover:underline"
              >
                About
              </Link>
              <Link
                to={AppRoutes.DOWNLOADS}
                className="text-muted-foreground text-sm hover:underline"
              >
                Downloads
              </Link>
              <ThemeToggle />
            </nav>
          </div>
        </footer>
      </div>

      <GlobalAudioPlayer />
      <AudioPlayerCore />

      <Toaster />
    </div>
  )
}

// const DownloadLink = () => {
//   return (
//     <Link
//       to={AppRoutes.DOWNLOADS}
//       className={buttonVariants({
//         size: 'icon',
//         variant: 'secondary',
//         className: 'relative overflow-hidden bg-transparent',
//       })}
//     >
//       <div className="animate-gradient from-background absolute inset-0 bg-gradient-to-tr to-indigo-300 bg-[length:200%_200%]" />
//       <div className="absolute inset-0 flex items-center justify-center">
//         <DownloadIcon className="h-4 w-4" />
//       </div>
//     </Link>
//   )
// }
