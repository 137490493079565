import { useAnalytics } from '@/hooks/useAnalytics'
import { api } from '@/lib/api'
import { AppRoutes } from '@/routes'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoaderCircleIcon } from 'lucide-react'
import { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { Button } from './ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from './ui/form'
import { Input } from './ui/input'
import { toast } from './ui/use-toast'

const FormFieldsSchema = z.object({
  url: z.string().url(),
})

type FormFields = z.infer<typeof FormFieldsSchema>

interface Props {
  defaultUrl?: string
  onSuccess?: (url: string) => void
  interrupt?: () => void
  setInterruptedAction?: (action: () => () => void) => void
}

export function CreateArchiveForm(props: Props) {
  const { defaultUrl, onSuccess, interrupt, setInterruptedAction } = props

  const form = useForm<FormFields>({
    resolver: zodResolver(FormFieldsSchema),
    defaultValues: {
      url: defaultUrl ?? '',
    },
  })

  const analytics = useAnalytics()
  const navigate = useNavigate()

  const mutation = api.archives.scrape.useMutation({
    onSuccess: async ({ url, alreadyExists }) => {
      if (alreadyExists) {
        toast({
          title: 'Archive Already Exists',
          description: 'The provided URL has already been archived',
        })
      } else {
        await new Promise((resolve) => setTimeout(resolve, 2_000))
      }

      navigate(AppRoutes.buildArchiveRoute(url))

      form.reset()
      onSuccess?.(url)
    },
    onError: (error) => {
      toast({
        title: 'Error Archiving',
        description: error.message,
        variant: 'destructive',
      })
    },
  })

  const saveArchive = (data: FormFields) => {
    analytics.sendEvent('page_scraped', { sraped_url: data.url })

    mutation.mutate({ url: data.url })
  }

  const onSubmit = (data: FormFields) => {
    if (interrupt) {
      setInterruptedAction?.(() => () => saveArchive(data))
      interrupt()
    } else {
      saveArchive(data)
    }
  }

  const autoSubmitted = useRef(false)

  useEffect(() => {
    if (defaultUrl && !autoSubmitted.current) {
      autoSubmitted.current = true

      saveArchive({ url: defaultUrl })
    }
  }, [defaultUrl])

  return (
    <Form {...form}>
      <form
        className={`flex flex-1 flex-col items-center justify-between gap-4`}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="url"
          disabled={mutation.isPending}
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>URL</FormLabel>
              <FormControl>
                <Input type="url" {...field} autoFocus={!defaultUrl} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button
          type="submit"
          variant={'theme'}
          className={`w-full px-5`}
          disabled={mutation.isPending}
        >
          {mutation.isPending ? 'Archiving...' : 'Archive'}
          {mutation.isPending && <LoaderCircleIcon className="animate-spin" />}
        </Button>
      </form>
    </Form>
  )
}
