import { Button, buttonVariants } from '@/components/ui/button'
import {
  DialogHeader,
  DialogFooter,
  DialogDescription,
  DialogTitle,
  Dialog,
  DialogClose,
  DialogContent,
} from '@/components/ui/dialog'
import { toast } from '@/components/ui/use-toast'
import { useAnalytics } from '@/hooks/useAnalytics'
import useUser from '@/hooks/useUser'
import { api } from '@/lib/api'
import { compactNumber } from '@/lib/formatting'
import { cn } from '@/lib/utils'
import { AppRoutes } from '@/routes'
import { IArchive } from '@/types'
import {
  BookmarkIcon,
  LinkIcon,
  CopyIcon,
  CalendarIcon,
  DownloadIcon,
  MessageSquareIcon,
  ChartNoAxesCombinedIcon,
} from 'lucide-react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StatsModal } from './StatsModal'
import { VerticalTimeline } from './VerticalTimeline'
import { ToastAction } from '@/components/ui/toast'
import { Wallet } from '@canonicxyz/wallet-sdk'
import { TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { Tooltip } from '@/components/ui/tooltip'
import { PopoverContent } from '@/components/ui/popover'
import { PopoverTrigger } from '@/components/ui/popover'
import { Popover } from '@/components/ui/popover'

interface Props {
  archive: IArchive
  totalComments: number | undefined
}

export function ActionButtonGroup(props: Props) {
  const { archive, totalComments } = props

  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [showUpvoteStatsModal, setShowUpvoteStatsModal] = useState(false)

  const analytics = useAnalytics()
  const { loggedIn } = useUser()
  const navigate = useNavigate()

  const { data: bookmark, refetch: refetchBookmark } =
    api.user.bookmark.useQuery({ url: archive.url }, { enabled: loggedIn })

  const toggleBookmarkMutation = api.user.toggleBookmark.useMutation({
    onSuccess: async ({ success }) => {
      await refetchBookmark()
      toast({
        title: success === 'added' ? 'Saved' : 'Removed from saved',
        action: (
          <ToastAction
            altText="View saved"
            onClick={() => {
              navigate(AppRoutes.SAVED)
            }}
          >
            View saved
          </ToastAction>
        ),
      })
    },
    onError: (error) => {
      toast({
        title: 'Failed to saved',
        description: error.message,
        variant: 'destructive',
      })
    },
  })

  const hashTimestamp = archive.hash_tx?.block_timestamp
  const fullTextTimestamp = archive.full_text_tx?.block_timestamp

  const timelineMarks = [
    {
      date: hashTimestamp ? hashTimestamp * 1000 : undefined,
      label: `Saved on chain${fullTextTimestamp ? ' (hash)' : ''}`,
    },
    {
      date: fullTextTimestamp ? fullTextTimestamp * 1000 : undefined,
      label: `Saved on chain${hashTimestamp ? ' (full text)' : ''}`,
    },
    { date: archive.date, label: 'Archived' },
    {
      date: archive.published_at ? +new Date(archive.published_at) : undefined,
      label: 'Originally published',
    },
  ]

  return (
    <>
      {loggedIn && (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              size="icon"
              variant="secondary"
              className="h-8 w-8"
              onClick={() =>
                toggleBookmarkMutation.mutate({ url: archive.url })
              }
              disabled={toggleBookmarkMutation.isPending}
            >
              <BookmarkIcon
                className={`size-5 ${cn({ 'fill-theme stroke-theme': bookmark })}`}
              />
            </Button>
          </TooltipTrigger>
          <TooltipContent>{bookmark ? 'Unsave' : 'Save'}</TooltipContent>
        </Tooltip>
      )}
      <Tooltip>
        <Popover>
          <PopoverTrigger asChild>
            <TooltipTrigger asChild>
              <Button size="icon" variant="secondary" className="h-8 w-8">
                <LinkIcon className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
          </PopoverTrigger>

          <PopoverContent className="ml-4 grid w-full max-w-[calc(100vw-2rem)] grid-cols-[1fr,auto] gap-x-3 sm:max-w-max">
            <a
              href={archive.url}
              target="_blank"
              rel="noreferrer noopener"
              className="text-theme text-sm underline-offset-4 [word-break:break-word] hover:underline"
            >
              {archive.url}
            </a>
            <button
              onClick={() => {
                navigator.clipboard.writeText(archive.url ?? '')
                toast({ title: 'Copied' })
              }}
            >
              <CopyIcon className="h-5 w-5 sm:h-4 sm:w-4" />
            </button>
          </PopoverContent>
        </Popover>

        <TooltipContent>Source URL</TooltipContent>
      </Tooltip>

      <Tooltip>
        <Popover>
          <PopoverTrigger asChild>
            <TooltipTrigger asChild>
              <Button size="icon" variant="secondary" className="h-8 w-8">
                <CalendarIcon className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
          </PopoverTrigger>
          <PopoverContent className="ml-4 w-auto gap-2 text-sm">
            <VerticalTimeline marks={timelineMarks} />
          </PopoverContent>
        </Popover>

        <TooltipContent>Timeline</TooltipContent>
      </Tooltip>

      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            size="icon"
            variant="secondary"
            className="h-8 w-8"
            onClick={() => setShowDownloadModal(true)}
          >
            <DownloadIcon className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Download archive</TooltipContent>
      </Tooltip>

      <Tooltip>
        <TooltipTrigger asChild>
          <a
            className={buttonVariants({
              variant: 'secondary',
              size: 'icon',
              className: 'h-8 w-auto px-2',
            })}
            href="#comments"
          >
            <MessageSquareIcon className="h-4 w-4" />{' '}
            {compactNumber(totalComments)}
          </a>
        </TooltipTrigger>
        <TooltipContent>Comments</TooltipContent>
      </Tooltip>

      {archive.total_watt_hours && (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              size="icon"
              variant="secondary"
              className="h-8 w-8"
              onClick={() => setShowUpvoteStatsModal(true)}
            >
              <ChartNoAxesCombinedIcon className="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Stats</TooltipContent>
        </Tooltip>
      )}
      <StatsModal
        archive={archive}
        open={showUpvoteStatsModal}
        setOpen={setShowUpvoteStatsModal}
      />

      <Dialog open={showDownloadModal} onOpenChange={setShowDownloadModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Download Archive</DialogTitle>
            <DialogDescription>
              By clicking download you'll download the original text of the
              page. If the archive has been saved on chain, the download will
              also contain a{' '}
              <span className="text-primary font-mono">readme.txt</span> file
              with instructions for how to verify the authenticity of the page.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="space-between flex justify-center gap-2">
            <DialogClose asChild>
              <Button variant="secondary">Cancel</Button>
            </DialogClose>
            <a
              href={`/api/download?url=${archive.url}`}
              className={buttonVariants({})}
              onClick={() =>
                analytics.sendEvent('archive_downloaded', { url: archive.url })
              }
            >
              Download <DownloadIcon className="ml-1.5 h-4 w-4" />
            </a>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}
