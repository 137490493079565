import { Button, buttonVariants } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/use-toast'
import useBalance from '@/hooks/useBalance'
import useUser from '@/hooks/useUser'
import { api } from '@/lib/api'
import { cn } from '@/lib/utils'
import { AppRoutes } from '@/routes'
import { Wallet } from '@canonicxyz/wallet-sdk'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoaderCircleIcon } from 'lucide-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { z } from 'zod'
import { EditProfileForm } from './EditProfileForm'
import { LoginBenefits } from './LoginBenefits'

const MASTER_PATH = 'm/0'

const loginSchema = z.object({
  email: z.string().min(1).email('Invalid email address'),
  password: z.string().min(1, 'Password is required'),
})

const signupSchema = loginSchema.extend({
  confirmPassword: z.string().min(1, 'Password confirmation is required'),
})

type LoginFormValues = z.infer<typeof loginSchema>
type SignupFormValues = z.infer<typeof signupSchema>

interface Props {
  view: 'login' | 'signup'
  className?: string
  /** Use state to switch the view between login/signup instead of hard
   * links. Useful when using the component embedded instead of a
   * standalone login page.
   */
  inline?: boolean
  onSuccess?: () => void
  hideHeader?: boolean
  hideBenefits?: boolean
}

export function Authentication(props: Props) {
  const { inline, hideHeader, className, hideBenefits } = props

  const [view, setView] = useState(props.view)
  const [profileIncomplete, setProfileIncomplete] = useState(false)

  const { fetchUser, setAuthToken, loggedIn } = useUser()
  const { fetchBalance } = useBalance()

  const [loading, setLoading] = useState(false)

  const form = useForm<LoginFormValues | SignupFormValues>({
    resolver: zodResolver(view === 'login' ? loginSchema : signupSchema),
    defaultValues: { email: '', password: '', confirmPassword: '' },
  })

  const { refetch: refetchProfile } = api.user.profile.useQuery(undefined, {
    enabled: loggedIn,
  })

  const onSuccess = async () => {
    await fetchUser()
    await fetchBalance()
    await refetchProfile()

    props.onSuccess?.()
  }

  const finalizeLoginMutation = api.authentication.finalizeLogin.useMutation({
    onSuccess: async ({ token, profile }) => {
      setAuthToken(token)

      if (!profile) {
        return setProfileIncomplete(true)
      }

      await onSuccess()
    },
    onError: (e) => {
      const title = view === 'login' ? 'Login failed' : 'Signup failed'
      toast({
        title,
        variant: 'destructive',
        description: e instanceof Error && e.message,
      })
    },
    onSettled: () => {
      setLoading(false)
    },
  })

  const onSubmit = async (data: LoginFormValues | SignupFormValues) => {
    const { email, password } = data

    if ('confirmPassword' in data) {
      if (data.confirmPassword !== data.password) {
        form.setError('confirmPassword', { message: 'Passwords do not match' })
        return
      }
    }

    setLoading(true)

    try {
      try {
        await Wallet.login({ email, password, property: 'ark' })
      } catch (err) {
        if (view == 'signup') {
          await Wallet.createAccount({ email, password, property: 'ark' })
        } else {
          throw err
        }
      }

      const user = await Wallet.getUser()
      if (!user) throw Error('Unable to fetch user')

      const msg = (+new Date()).toString()
      const sig = Wallet.sign(Buffer.from(msg), MASTER_PATH)

      finalizeLoginMutation.mutateAsync({ pubkey: user.pubKey, msg, sig })
    } catch (err) {
      const title = view === 'login' ? 'Login failed' : 'Signup failed'
      setLoading(false)
      toast({
        title,
        variant: 'destructive',
        description: err instanceof Error && err.message,
      })
    }
  }

  return (
    <div
      className={cn(
        'flex w-full flex-col items-center justify-center',
        { 'gap-6': !hideBenefits },
        className,
      )}
    >
      {profileIncomplete ? (
        <div className="border-border flex flex-col gap-4 rounded-lg border p-4">
          <h2 className="text-2xl font-semibold leading-none tracking-tight">
            Complete your profile
          </h2>
          <p className="text-muted-foreground text-sm">
            Please complete your profile in order to continue.
          </p>
          <EditProfileForm
            onSuccess={async () => {
              await onSuccess()

              setProfileIncomplete(false)
            }}
          />
        </div>
      ) : (
        <>
          {!hideHeader && (
            <h3 className="w-full text-left text-2xl font-semibold leading-none tracking-tight">
              {view === 'login' ? 'Login' : 'Sign up'}
            </h3>
          )}
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
              <div className="space-y-4">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input type="email" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>Password</FormLabel>
                      <FormControl>
                        <Input type="password" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {view === 'signup' && (
                  <FormField
                    control={form.control}
                    name="confirmPassword"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Confirm Password</FormLabel>
                        <FormControl>
                          <Input type="password" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
                <div className="gap flex flex-col">
                  <Button
                    className="w-full"
                    type="submit"
                    disabled={loading}
                    variant="theme"
                  >
                    {view === 'login' ? 'Log in' : 'Sign up'}{' '}
                    {loading && (
                      <LoaderCircleIcon className="size-4 animate-spin" />
                    )}
                  </Button>
                  <div className="flex items-center justify-center text-sm">
                    {view === 'login'
                      ? "Don't have an account?"
                      : 'Already have an account?'}
                    {inline ? (
                      <Button
                        variant="link"
                        className="ml-1 p-0 underline underline-offset-4"
                        type="button"
                        onClick={() => {
                          setView((c) => (c === 'login' ? 'signup' : 'login'))
                        }}
                      >
                        {view === 'login' ? 'Sign up' : 'Login'}
                      </Button>
                    ) : (
                      <Link
                        to={
                          view === 'login' ? AppRoutes.SIGNUP : AppRoutes.LOGIN
                        }
                        className={cn(
                          buttonVariants({
                            variant: 'link',
                            className: 'ml-1 p-0 underline underline-offset-4',
                          }),
                        )}
                      >
                        {view === 'login' ? 'Sign up' : 'Login'}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </Form>
          {!hideBenefits && (
            <>
              <div className="relative my-6 w-full max-w-md">
                <div className="absolute inset-0 flex items-center">
                  <span className="w-full border-t" />
                </div>
                <div className="relative flex justify-center text-xs uppercase">
                  <span className="bg-background text-muted-foreground px-2 tracking-wider">
                    benefits
                  </span>
                </div>
              </div>
              <LoginBenefits />
            </>
          )}
        </>
      )}
    </div>
  )
}
