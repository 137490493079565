import { EditProfileForm } from '@/components/EditProfileForm'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { api } from '@/lib/api'
import { AppRoutes } from '@/routes'
import { Navigate, useNavigate } from 'react-router-dom'

export default function NewProfilePage() {
  const navigate = useNavigate()

  const { data: profile } = api.user.profile.useQuery()

  const utils = api.useUtils()

  if (profile) {
    return <Navigate to={AppRoutes.buildProfileRoute(profile.username)} />
  }

  return (
    <div className="container max-w-2xl py-8">
      <Card>
        <CardHeader>
          <CardTitle>Create Profile</CardTitle>
          <CardDescription>
            Please create a profile before continuing.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <EditProfileForm
            onSuccess={(profile) => {
              utils.profiles.get.setData(
                { username: profile.username },
                { ...profile, __v: 0 },
              )
              utils.user.profile.setData(undefined, { ...profile, __v: 0 })

              navigate(AppRoutes.buildProfileRoute(profile.username))
            }}
          />
        </CardContent>
      </Card>
    </div>
  )
}
