import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { memo, useMemo, useState } from 'react'

import { FeeSelection } from '@/components/FeeSelection'
import { PayButton } from '@/components/PayButton'
import PayPlugin from '@/components/PayPlugin'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Separator } from '@/components/ui/separator'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import useUser from '@/hooks/useUser'
import { api } from '@/lib/api'
import { EMAILS, PAYMENT_PAYMAIL } from '@/lib/constants'
import { Env } from '@/lib/env'
import { formatCurrency } from '@/lib/formatting'
import { cn } from '@/lib/utils'
import { IArchive } from '@/types'
import { Wallet } from '@canonicxyz/wallet-sdk'
import { FileTextIcon, FingerprintIcon, LoaderCircleIcon } from 'lucide-react'
import { OnChainDetails } from './OnChainDetails'
import { Unsaved } from './Unsaved'

const SAVE_FORMAT = {
  STANDARD: 'standard',
  PREMIUM: 'premium',
}

interface Props {
  archive: IArchive
  payModal: boolean
  setPayModal: (modalOpen: boolean) => void
}

export const OnChainStatus = memo(function ArchiveHeader(props: Props) {
  const {
    archive,
    payModal: payModalOpen,
    setPayModal: setPayModalOpen,
  } = props

  const { loggedIn } = useUser()

  const [customSatsPerByte, setCustomSatsPerByte] = useState<number | null>(
    null,
  )
  const [customSatsPerByteValid, setCustomSatsPerByteValid] = useState(true)
  const [totalUsd, setTotalUsd] = useState<number | null>(null)
  /** Selected media URLs to additionally save on chain */
  const [selectedMedia, setSelectedMedia] = useState(new Set<string>())

  const { data: btcInfo } = api.bitcoin.priceInfo.useQuery(undefined)

  const { data: walletBalance, isLoading: loadingBalance } =
    api.bitcoin.walletBalance.useQuery(undefined, {
      refetchOnWindowFocus: !payModalOpen,
      refetchOnReconnect: !payModalOpen,
      refetchOnMount: !payModalOpen,
    })

  const { data: fees } = api.bitcoin.estimateFees.useQuery(
    {
      urls: [archive.url],
      satsPerByte: customSatsPerByte ? customSatsPerByte : undefined,
      includedMedia: Array.from(selectedMedia),
    },
    {
      enabled:
        archive && (!archive.full_text_tx?.txid || !archive.hash_tx?.txid),
      refetchOnWindowFocus: !payModalOpen,
      refetchOnReconnect: !payModalOpen,
      refetchOnMount: !payModalOpen,
      placeholderData: (prev) => prev,
    },
  )

  const saveMutation = api.user.saveOnChain.useMutation()

  const defaultTitle = archive.title
    ? archive.title
    : archive.format !== 'mhtml'
      ? archive.text_content.trim().split('\n')[0].slice(0, 100).trim()
      : ''

  const [saveFormat, setSaveFormat] = useState<
    (typeof SAVE_FORMAT)[keyof typeof SAVE_FORMAT]
  >(SAVE_FORMAT.PREMIUM)

  const [title, setTitle] = useState(defaultTitle)

  const memoizedUrl = useMemo(() => archive.url, [archive.url])

  const standardFees = fees?.standardFeesUsd
  const premiumFees = fees?.premiumFeesUsd

  const showStandardPayment =
    walletBalance &&
    standardFees !== null &&
    typeof standardFees !== 'undefined' &&
    walletBalance.total_usd > standardFees

  const showPremiumPayment =
    walletBalance &&
    premiumFees !== null &&
    typeof premiumFees !== 'undefined' &&
    walletBalance.total_usd > premiumFees

  const loading = loadingBalance || typeof fees === 'undefined'

  const callbackUrl = useMemo(() => {
    const url = new URL(`${Env.BASE_URL}/api/callbacks/payment`)

    url.searchParams.set('premium', 'false')
    url.searchParams.set('title', encodeURIComponent(title))

    if (customSatsPerByte) {
      url.searchParams.set('satsPerByte', customSatsPerByte.toString())
    }

    return url.toString()
  }, [title, customSatsPerByte])

  const callbackUrlPremium = useMemo(() => {
    const url = new URL(callbackUrl)

    url.searchParams.set('premium', 'true')

    return url.toString()
  }, [callbackUrl])

  const onPaymentSuccess = async (
    type: 'standard' | 'premium',
    paymentTxid: string,
  ) => {
    saveMutation.mutateAsync({
      url: archive.url,
      // No use in saving title if it already exists
      title: !archive.title ? title : undefined,
      paymentTxid,
      type,
      satsPerByte: customSatsPerByte ?? undefined,
      includedMedia: Array.from(selectedMedia),
    })
  }

  if (archive.minting_in_progress) {
    return (
      <Alert>
        <LoaderCircleIcon className="h-4 w-4 animate-spin" />
        <AlertTitle>Saving in Progress</AlertTitle>
        <AlertDescription>
          This page is currently being saved on Bitcoin. This could take several
          minutes or hours depending on the current blockchain conditions. You
          can view the final transaction below once it is complete.
        </AlertDescription>
      </Alert>
    )
  }

  const disableTitleInput = !!archive.title

  const toggleMediaSelected = (url: string) => {
    setSelectedMedia((prev) => {
      const newSet = new Set(prev)

      if (newSet.has(url)) {
        newSet.delete(url)
      } else {
        newSet.add(url)
      }

      return newSet
    })
  }

  const Payment = ({
    fees,
    type,
  }: {
    fees?: number | null
    type: 'standard' | 'premium'
  }) => {
    if (!fees) return null

    return (
      <>
        {loggedIn ? (
          <PayButton
            className="w-full"
            ticker={!Env.PROD ? 'SIGNET' : 'BTC'}
            label={`Pay with ${!Env.PROD ? 'SIGNET' : 'BTC'}`}
            satsPerByte={customSatsPerByte ?? undefined}
            onSuccess={({ txid }) => onPaymentSuccess(type, txid)}
            onTotalChange={(total) => setTotalUsd(total)}
            disabled={!customSatsPerByteValid}
            outputs={[
              {
                addressOrPaymail: PAYMENT_PAYMAIL,
                amount: fees,
                currency: 'USD',
              },
            ]}
          />
        ) : !customSatsPerByteValid ? null : (
          <div className="mt-4 flex w-full justify-center">
            <PayPlugin
              paymail={PAYMENT_PAYMAIL}
              sessionId={memoizedUrl}
              productName={`Ark Archive - ${memoizedUrl}`}
              price={fees}
              receiptEmail={EMAILS.RECEIPT}
              callbackUrl={
                type === 'standard' ? callbackUrl : callbackUrlPremium
              }
            />
          </div>
        )}
      </>
    )
  }

  return (
    <div className="flex flex-col gap-2">
      {(archive.hash_tx?.txid || archive.full_text_tx?.txid) && (
        <OnChainDetails
          archive={archive}
          openPaymentModal={() => props.setPayModal(true)}
        />
      )}

      {!archive.hash_tx?.txid && !archive.full_text_tx?.txid && (
        <Unsaved {...props} />
      )}

      <Dialog open={payModalOpen} onOpenChange={setPayModalOpen}>
        <DialogContent aria-describedby={undefined}>
          <DialogHeader>
            <DialogTitle>Save on Bitcoin</DialogTitle>
            <DialogDescription>
              Permanently store this article on the Bitcoin blockchain
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-6">
            <div className="space-y-3">
              <Label htmlFor="title" className="text-primary mb-2 block">
                Title
              </Label>
              <Input
                id="title"
                value={title}
                disabled={disableTitleInput}
                onChange={(e) => setTitle(e.target.value.slice(0, 100))}
                placeholder="Enter a title for this archive"
                className={cn({ truncate: disableTitleInput })}
              />
            </div>
            <Separator />
            <div className="space-y-3">
              <Label className="text-sm font-medium">Save Options</Label>
              <Tabs
                defaultValue={SAVE_FORMAT.PREMIUM}
                value={saveFormat}
                onValueChange={(value) =>
                  setSaveFormat(value as 'standard' | 'premium')
                }
                className="w-full"
              >
                <TabsList className="w-full">
                  <TabsTrigger
                    value={SAVE_FORMAT.PREMIUM}
                    className="flex flex-1 items-center gap-2"
                  >
                    <FileTextIcon className="size-4" /> Full Text
                  </TabsTrigger>
                  <TabsTrigger
                    value={SAVE_FORMAT.STANDARD}
                    className="flex flex-1 items-center gap-2"
                  >
                    <FingerprintIcon className="size-4" /> Hash Only
                  </TabsTrigger>
                </TabsList>
                <TabsContent value={SAVE_FORMAT.PREMIUM} className="mt-2">
                  <p className="text-muted-foreground text-sm">
                    Saves the complete article text and optional media on the
                    blockchain. More expensive but preserves all content.
                  </p>
                </TabsContent>
                <TabsContent value={SAVE_FORMAT.STANDARD} className="mt-2">
                  <p className="text-muted-foreground text-sm">
                    Saves only a cryptographic hash of the article. More
                    affordable but requires external verification.
                  </p>
                </TabsContent>
              </Tabs>
            </div>
            <Separator />

            {!!archive.media?.length && (
              <>
                <div className="space-y-3">
                  <div className="flex items-center justify-between">
                    <Label className="text-sm font-medium">Include Media</Label>
                    <span className="text-muted-foreground text-xs">
                      {selectedMedia.size} of {archive.media.length} selected
                    </span>
                  </div>

                  <div className="grid grid-cols-3 gap-3">
                    {archive.media.map((media) => (
                      <Card
                        key={media.url}
                        className={`cursor-pointer overflow-hidden ${cn({
                          'ring-primary ring-2':
                            selectedMedia.has(media.url) &&
                            saveFormat === SAVE_FORMAT.PREMIUM,
                          'cursor-not-allowed':
                            saveFormat === SAVE_FORMAT.STANDARD,
                        })}`}
                        onClick={
                          saveFormat === SAVE_FORMAT.PREMIUM
                            ? () => toggleMediaSelected(media.url)
                            : undefined
                        }
                      >
                        <div className="relative aspect-square">
                          <img
                            src={media.url}
                            alt="Media content"
                            className={`h-full w-full object-cover transition-opacity ${cn({ 'opacity-40': !selectedMedia.has(media.url) || saveFormat === SAVE_FORMAT.STANDARD, 'hover:opacity-100': saveFormat === SAVE_FORMAT.PREMIUM })}`}
                          />
                          <Badge
                            variant={
                              selectedMedia.has(media.url)
                                ? 'secondary'
                                : 'default'
                            }
                            className="absolute bottom-1 right-1 text-xs"
                          >
                            +
                            {
                              Wallet.formatDollars(
                                media.sat_per_byte_estimate *
                                  (customSatsPerByte ?? 1),
                                btcInfo?.price ?? 0,
                              ).string
                            }
                          </Badge>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
                <Separator />
              </>
            )}

            <div className={cn('space-y-3')}>
              <FeeSelection
                onChange={(fee) => {
                  setCustomSatsPerByte(fee.satsPerByte)
                  setCustomSatsPerByteValid(fee.valid)
                }}
              />
            </div>
            {loading ? (
              <div
                className={`mt-6 flex justify-center ${cn({ 'min-h-[496px]': !loggedIn })}`}
              >
                <LoaderCircleIcon className="h-5 w-5 animate-spin" />
              </div>
            ) : (
              <div className="flex flex-col gap-3">
                <div className="flex-col gap-3 sm:flex-col">
                  <div className="flex w-full items-center justify-between">
                    <div className="text-sm">
                      Total Cost:
                      <span className="ml-1 text-lg font-bold">
                        {loggedIn
                          ? formatCurrency(totalUsd ?? 0)
                          : saveFormat === SAVE_FORMAT.PREMIUM
                            ? formatCurrency(premiumFees ?? 0)
                            : formatCurrency(standardFees ?? 0)}
                      </span>
                    </div>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setPayModalOpen(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>

                {saveFormat === SAVE_FORMAT.PREMIUM ? (
                  <div>
                    <div className={cn({ invisible: !showPremiumPayment })}>
                      <Payment fees={premiumFees} type="premium" />
                    </div>
                    <div className={cn({ invisible: showPremiumPayment })}>
                      Saving this archive with full text is currently
                      unavailable.
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={cn({ invisible: !showStandardPayment })}>
                      <Payment fees={standardFees} type="standard" />
                    </div>
                    <div className={cn({ invisible: showStandardPayment })}>
                      Saving this archive with hash only is currently
                      unavailable.
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
})
