import { NewArchiveModal } from '@/components/NewArchiveModal'
import { PriceSwitcher } from '@/components/PriceSwitcher'
import { Button, buttonVariants } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { api } from '@/lib/api'
import { compactNumber, formatFileSize } from '@/lib/formatting'
import { AppRoutes } from '@/routes'
import {
  ArrowRightIcon,
  ClockIcon,
  DatabaseIcon,
  PlusIcon,
  ShieldIcon,
} from 'lucide-react'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import FeedPage from './Feed/FeedPage'

export default function HomePage() {
  const { data: btcInfo } = api.bitcoin.priceInfo.useQuery()
  const { data: stats } = api.archives.stats.useQuery()

  const browseRef = useRef<HTMLElement>(null)

  const [url, setUrl] = useState('')
  const [showArchiveModal, setShowArchiveModal] = useState(false)

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setShowArchiveModal(true)
  }

  return (
    <div className="flex flex-col">
      <section className="bg-background text-foreground flex w-full flex-col items-center px-4 pt-36 sm:px-6">
        <div className="flex w-full flex-1 flex-col items-center justify-center text-center">
          <h1 className="lg:leading-tighter font-serif text-3xl sm:text-4xl md:text-5xl xl:text-[3.4rem] 2xl:text-[3.75rem]">
            Archive the Internet on Bitcoin
          </h1>
          {/* <p className="text-muted-foreground mt-2 max-w-2xl text-lg">
            Content creation + preservation that works.
          </p> */}
          <div className="mt-6 flex w-full max-w-screen-sm flex-col gap-1.5">
            {showArchiveModal && (
              <NewArchiveModal
                open={showArchiveModal}
                hideTrigger
                defaultUrl={url}
                onClose={() => setShowArchiveModal(false)}
              />
            )}
            <form
              className={`flex flex-1 flex-row items-center justify-between overflow-hidden rounded-md border focus-within:border-indigo-500`}
              onSubmit={onSubmit}
            >
              <Input
                type="text"
                placeholder={'Enter a URL'}
                onChange={(e) => setUrl(e.target.value)}
                className={`flex-1 rounded-md border-none px-3 focus-visible:rounded-r-none focus-visible:ring-0`}
                autoFocus
              />
              <Button
                type="submit"
                variant={'theme'}
                className={`rounded-none px-5 disabled:opacity-70`}
              >
                Archive
              </Button>
            </form>

            <Link
              to={AppRoutes.NEW_ARCHIVE}
              className="hover:border-foreground text-muted-foreground z-10 mx-auto inline-flex items-center gap-1 border-b border-transparent p-0 text-sm"
            >
              or create your own
              <PlusIcon className="h-4 w-4" />
            </Link>
          </div>
        </div>
      </section>

      <section className="mt-32 px-4 pb-12 pt-12 lg:pb-12" ref={browseRef}>
        <FeedPage pageSize={5} disableEndlessScroll hideFilters />
        <div className="mt-4 flex justify-center">
          <Link
            to={AppRoutes.FEED}
            className={`${buttonVariants({ variant: 'ghost' })} flex items-center gap-1.5`}
          >
            View all <ArrowRightIcon className="h-5 w-5" />
          </Link>
        </div>
      </section>

      <section className="bg-muted/50 border-t">
        <div className="container py-12 lg:py-28">
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            <div className="flex flex-col items-center space-y-4 text-center">
              <ShieldIcon className="text-primary h-8 w-8" />
              <h3 className="text-xl font-semibold">Immutable Storage</h3>
              <p className="text-muted-foreground">
                Your content is permanently stored on the Bitcoin blockchain,
                ensuring it can never be altered or deleted.
              </p>
            </div>
            <div className="flex flex-col items-center space-y-4 text-center">
              <ClockIcon className="text-primary h-8 w-8" />
              <h3 className="text-xl font-semibold">Easy Archiving</h3>
              <p className="text-muted-foreground">
                Archive any webpage or content instantly with just a URL, or
                submit your own text.
              </p>
            </div>
            <div className="flex flex-col items-center space-y-4 text-center">
              <DatabaseIcon className="text-primary h-8 w-8" />
              <h3 className="text-xl font-semibold">Decentralized Access</h3>
              <p className="text-muted-foreground">
                Access your archived content from anywhere, anytime, without
                relying on centralized servers.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="border-t">
        <div className="px-4 pb-4 pt-12 md:container lg:pb-16 lg:pt-28">
          <div className="mx-auto max-w-5xl text-center">
            <div className="mt-8 grid gap-4 md:grid-cols-3">
              <div className="bg-card rounded-lg border p-6">
                <div className="text-4xl font-bold">
                  {compactNumber(stats?.totalArchives)}
                </div>
                <p className="text-muted-foreground text-sm">
                  Archives Created
                </p>
              </div>
              <div className="bg-card rounded-lg border p-6">
                <div className="text-4xl font-bold">
                  {formatFileSize(stats?.totalOnChainSize ?? 0)}
                </div>
                <p className="text-muted-foreground text-sm">On-Chain Data</p>
              </div>
              <div className="bg-card rounded-lg border p-6">
                <div className="text-4xl font-bold">
                  <PriceSwitcher
                    sats={stats?.totalFeesSats ?? 0}
                    bitcoinPrice={btcInfo?.price ?? 0}
                    className="cursor-pointer"
                  />
                </div>
                <p className="text-muted-foreground text-sm">Miner Fees Paid</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
