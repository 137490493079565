import { api } from '@/lib/api'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { InfoIcon, LoaderCircleIcon } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { formatCurrency } from '@/lib/formatting'
import { Helmet } from 'react-helmet'
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { ArchiveTable } from './components/ArchiveTable'
import { useDebouncedInput } from '@/hooks/useDebouncedInput'
import { ArchiveCard } from '@/components/ArchiveCard'

const DEFAULT_PAGE_SIZE = 10
const INITIAL_PAGE = 1

export default function AdminPage() {
  const { adminSecret } = useParams<{ adminSecret: string }>()

  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const {
    debouncedValue: debouncedSearch,
    inputValue: search,
    handleInputChange: handleSearchChange,
  } = useDebouncedInput({ initialValue: '', delay: 250 })

  const {
    data: featuredArchives,
    isLoading: loadingFeatured,
    refetch: refetchFeatured,
  } = api.archives.browse.useQuery({
    status: 'all',
    featured: true,
    limit: 999,
  })

  const {
    data,
    isLoading,
    refetch: refetchApprovals,
  } = api.admin.approvalRequired.useQuery(
    {
      secret: adminSecret ?? '',
      limit: pageSize,
      search: debouncedSearch,
      cursor: currentPage,
    },
    {
      enabled: !!adminSecret,
    },
  )

  const { data: walletBalance } = api.bitcoin.walletBalance.useQuery()
  const { data: btcInfo } = api.bitcoin.priceInfo.useQuery()

  const updatePageSize = (size: number) => {
    setPageSize(size)
    setCurrentPage(INITIAL_PAGE)
  }

  const refetch = () => {
    refetchApprovals()
    refetchFeatured()
  }

  if (!adminSecret) return <div>Unauthorized.</div>

  return (
    <div className="mx-auto w-full max-w-screen-xl pt-8">
      <div className="flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
        <h1 className="mb-1 text-3xl font-bold">Admin</h1>
        <div className="flex items-center gap-2">
          <div>
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="outline" className="flex items-center gap-1.5">
                  BTC Info <InfoIcon className="h-4 w-4" />
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>BTC Info</DialogTitle>
                </DialogHeader>
                <pre>{JSON.stringify(btcInfo, null, 2)}</pre>
              </DialogContent>
            </Dialog>
          </div>
          <div>
            Wallet Balance: {formatCurrency(walletBalance?.total_usd ?? 0)}
          </div>
        </div>
      </div>
      <div className="mt-4">
        <ArchiveTable
          archives={data?.archives ?? []}
          hasNextPage={!!data?.nextPage}
          hasPreviousPage={currentPage > 1}
          pageSize={pageSize}
          currentPage={currentPage}
          pageCount={data?.totalPages ?? 0}
          isLoading={isLoading}
          adminSecret={adminSecret}
          walletBalance={walletBalance?.total_usd}
          refetchData={refetch}
          nextPage={async () => {
            setCurrentPage(currentPage + 1)
          }}
          previousPage={async () => {
            setCurrentPage(currentPage - 1)
          }}
          setPageSize={updatePageSize}
          search={search}
          handleSearchChange={handleSearchChange}
        />
      </div>
      <h2 className="mt-8 text-2xl font-bold">Featured</h2>
      <p className="mb-6">
        These archives will be displayed on the homepage and other featured
        sections.
      </p>
      {typeof featuredArchives !== 'undefined' &&
        !featuredArchives.archives.length && (
          <p className="text-muted-foreground text-sm">
            There are no featured archives.
          </p>
        )}
      {loadingFeatured && (
        <div className="flex justify-center">
          <LoaderCircleIcon className="animate-spin" />
        </div>
      )}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        {featuredArchives?.archives.map((archive) => (
          <ArchiveCard
            key={archive.url}
            archive={archive}
            btcPriceUsd={0}
            hideUpvote
          />
        ))}
      </div>
    </div>
  )
}
