import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import useUser from '@/hooks/useUser'
import { api, apiExtra } from '@/lib/api'
import { formatFileSize } from '@/lib/formatting'
import { IProfile } from '@/types'
import { zodResolver } from '@hookform/resolvers/zod'
import { MAX_PROFILE_IMAGE_SIZE } from 'backend/src/lib/constants'
import { CheckIcon, ImageIcon, XIcon } from 'lucide-react'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { toast } from './ui/use-toast'
import { Navigate } from 'react-router-dom'
import { AppRoutes } from '@/routes'

const formSchema = z.object({
  name: z.string().min(1),
  username: z.string().min(1),
  bio: z.string().optional(),
})

type FormData = z.infer<typeof formSchema>

interface Props {
  onSuccess: (profile: IProfile) => void
}

export function EditProfileForm(props: Props) {
  const { onSuccess } = props

  const [imagePreview, setImagePreview] = useState<string | null>(null)
  const [imageFile, setImageFile] = useState<File | null>(null)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const { user, loggedIn } = useUser()

  const { data: profile } = api.user.profile.useQuery()

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: profile?.name || '',
      username: profile?.username || '',
      bio: profile?.bio || '',
    },
  })

  const username = form.watch('username')

  const { data: usernameValid } = api.profiles.usernameValid.useQuery({
    username,
  })

  const updateProfileMutation = api.user.updateProfile.useMutation({
    onSuccess: (profile) => {
      toast({
        title: 'Profile Saved',
      })

      setImagePreview(null)
      setImageFile(null)

      onSuccess(profile)
    },
    onError: (error) => {
      console.error(error)
      toast({
        title: 'Error Saving Profile',
        description: error.message,
        variant: 'destructive',
      })
    },
  })

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      if (file.size > MAX_PROFILE_IMAGE_SIZE) {
        toast({
          title: 'Image too large',
          description: `Must be less than ${formatFileSize(MAX_PROFILE_IMAGE_SIZE)}`,
          variant: 'destructive',
        })
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
        return
      }
      setImageFile(file)

      setImagePreview(URL.createObjectURL(file))

      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }

  const onSubmit = async (data: FormData) => {
    try {
      let image = profile?.image || user?.image || ''

      if (imageFile) {
        const { url } = await apiExtra.uploadProfileImage(imageFile)
        image = url
        if (!image) throw new Error('Failed to upload profile image')
      }

      if (!image) throw new Error('Missing profile image')

      // const
      await updateProfileMutation.mutateAsync({
        ...data,
        image,
      })
    } catch (err) {
      console.error(err)
      toast({
        title: 'Error Saving Profile',
        description: err instanceof Error ? err.message : 'Please try again',
        variant: 'destructive',
      })
    }
  }

  const profileImage = imagePreview || profile?.image || user?.image

  if (!loggedIn) return <Navigate to={AppRoutes.LOGIN} />

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="grid gap-4 py-4">
        <div className="flex flex-col items-center gap-2">
          <div className="relative">
            <div className="flex flex-col items-center gap-1">
              <label
                htmlFor="avatar-upload"
                className="group flex flex-col items-center gap-1 hover:cursor-pointer"
              >
                <Avatar className="h-24 w-24 rounded-full border-4 border-white shadow-md dark:border-slate-800">
                  <AvatarImage
                    src={profileImage}
                    alt={profile?.name}
                    className="object-cover"
                  />

                  {profileImage && (
                    <div className="absolute inset-0 flex cursor-pointer items-center justify-center bg-gray-900/50 opacity-0 transition-opacity group-hover:opacity-100">
                      <ImageIcon className="size-5 text-white" />
                    </div>
                  )}

                  <AvatarFallback>
                    <div className="flex h-full w-full cursor-pointer items-center justify-center">
                      <ImageIcon className="size-5" />
                    </div>
                  </AvatarFallback>
                </Avatar>
                <div className="text-primary text-sm group-hover:underline">
                  Select profile picture
                </div>
                <div className="text-muted-foreground text-xs">
                  Max size: {formatFileSize(MAX_PROFILE_IMAGE_SIZE)}
                </div>
                <input
                  id="avatar-upload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageChange}
                  ref={fileInputRef}
                />
              </label>
            </div>
            {imagePreview && (
              <Button
                variant="destructive"
                size="icon"
                className="absolute right-0 top-0 h-6 w-6 rounded-full"
                onClick={() => {
                  setImagePreview(null)
                  setImageFile(null)
                }}
              >
                <XIcon className="h-3 w-3" />
              </Button>
            )}
          </div>
        </div>

        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Username</FormLabel>
              <FormControl>
                <div className="relative">
                  <span className="text-muted-foreground absolute left-3 top-1/2 -translate-y-1/2">
                    @
                  </span>
                  <Input {...field} className="pl-7" />
                </div>
              </FormControl>
              <div className="text-muted-foreground flex items-center gap-2 text-sm">
                {!username ||
                !form.getFieldState('username')
                  .isDirty ? null : typeof usernameValid !== 'undefined' &&
                  !usernameValid.valid ? (
                  <>
                    <XIcon className="text-destructive size-4" />{' '}
                    {usernameValid?.invalidReason}
                  </>
                ) : (
                  <>
                    <CheckIcon className="size-4 text-green-500" /> Username is
                    available
                  </>
                )}
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="bio"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Bio</FormLabel>
              <FormControl>
                <Textarea {...field} rows={4} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          type="submit"
          disabled={updateProfileMutation.isPending || !usernameValid?.valid}
          className="w-full"
        >
          {updateProfileMutation.isPending ? 'Saving...' : 'Save'}
        </Button>
      </form>
    </Form>
  )
}
