import { formatDateLong, formatDateWithTime } from '@/lib/formatting'
import { cn, getBlockUrl, getOrdinalExplorerUrl } from '@/lib/utils'
import { AppRoutes } from '@/routes'
import { IArchive } from '@/types'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { PriceSwitcher } from './PriceSwitcher'
import { Badge } from './ui/badge'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from './ui/card'
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip'
import { UpvoteButton } from './upvoting/UpvoteButton'
import { ChartNoAxesCombinedIcon } from 'lucide-react'
import { StatsModal } from '@/pages/Archive/components/StatsModal'

interface Props {
  archive: IArchive
  btcPriceUsd: number
  hideUpvote?: boolean
}

export function ArchiveCard(props: Props) {
  const { archive, btcPriceUsd, hideUpvote } = props

  const [showStatsModal, setShowStatsModal] = useState(false)

  const ordinalId =
    archive.full_text_tx?.ordinal_id || archive.hash_tx?.ordinal_id
  const txid = archive.full_text_tx?.txid || archive.hash_tx?.txid
  const blockHeight =
    archive.full_text_tx?.block_height || archive.hash_tx?.block_height
  const satsPerByte =
    archive.full_text_tx?.sats_per_byte || archive.hash_tx?.sats_per_byte
  const totalSats =
    archive.full_text_tx?.total_sats || archive.hash_tx?.total_sats

  return (
    <div
      className={`relative flex w-full flex-1 flex-col items-center gap-2 pt-3 md:flex-row`}
    >
      {ordinalId && !hideUpvote && (
        <div
          className={`absolute inset-y-0 -left-12 hidden flex-col items-center justify-center break-words font-mono text-xs leading-normal md:flex`}
        >
          <UpvoteButton archive={archive} showInKwh />
        </div>
      )}
      <div className="relative w-full min-w-0 flex-1">
        <div className="absolute inset-x-0 -top-3 flex items-center justify-end gap-2 px-4 md:-top-3">
          {!ordinalId && <Badge variant="secondary">Off-chain</Badge>}

          {satsPerByte && (
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <Badge variant="default" className="select-none">
                    <PriceSwitcher
                      sats={totalSats ?? 0}
                      bitcoinPrice={btcPriceUsd}
                    />
                  </Badge>
                </div>
              </TooltipTrigger>
              <TooltipContent>Mining Fees</TooltipContent>
            </Tooltip>
          )}
        </div>
        <Link
          to={AppRoutes.buildArchiveRoute(archive.url)}
          rel="noreferrer"
          key={archive._id}
          className="block w-full min-w-0 transition duration-300 ease-in-out data-[closed]:-translate-x-full data-[closed]:opacity-0"
        >
          <Card className="flex h-full min-h-36 w-full min-w-0 flex-col justify-between">
            {archive.format === 'media' ? (
              <>
                {archive.title && (
                  <CardHeader className={`p-4 pb-0 ${cn({ 'pt-6': !!txid })}`}>
                    <CardTitle className="line-clamp-3 text-lg leading-tight underline-offset-4 group-hover:underline md:line-clamp-none md:truncate">
                      {archive.title}
                    </CardTitle>
                  </CardHeader>
                )}
                <CardContent className="flex gap-2 p-4 pb-2">
                  {archive.media
                    ?.filter((media) => media.mime_type.startsWith('image/'))
                    .map((media) => (
                      <img
                        key={media.url}
                        src={media.url}
                        alt={archive.title}
                        className="size-20 object-cover"
                      />
                    ))}
                </CardContent>
              </>
            ) : (
              <>
                <CardHeader className={`p-4 pb-2 ${cn({ 'pt-6': !!txid })}`}>
                  <CardTitle className="line-clamp-3 text-lg leading-tight underline-offset-4 group-hover:underline md:line-clamp-none md:truncate">
                    {archive.title || new URL(archive.url).hostname}
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-4 pb-2 pt-0 text-sm">
                  <p className="truncate">
                    {archive.excerpt ?? archive.text_content}
                  </p>
                </CardContent>
              </>
            )}
            <CardFooter className="text-muted-foreground flex items-center gap-2 p-4 pt-2 font-mono text-xs">
              <time
                title={formatDateWithTime(archive.date)}
                className="text-muted-foreground"
              >
                {formatDateLong(archive.date)}
              </time>

              {blockHeight ? (
                <>
                  <div>|</div>
                  <button
                    className="hover:underline"
                    onClick={(e) => {
                      e.preventDefault()
                      window.open(
                        getBlockUrl(blockHeight),
                        '_blank',
                        'noreferrer',
                      )
                    }}
                  >
                    Block #{blockHeight}
                  </button>
                </>
              ) : null}
              {archive.total_watt_hours ? (
                <>
                  <div>|</div>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setShowStatsModal(true)
                    }}
                    className="flex items-center gap-1.5 text-xs hover:underline"
                  >
                    Stats <ChartNoAxesCombinedIcon className="mb-1 size-4" />
                  </button>
                </>
              ) : null}
            </CardFooter>
          </Card>
        </Link>
        {/* Make sure this is outside the link otherwise all clicks
        on the modal will trigger the link */}
        <StatsModal
          archive={archive}
          open={showStatsModal}
          setOpen={() => setShowStatsModal(false)}
        />
      </div>
      <div
        className={`mb-4 inline-flex w-full max-w-full items-center justify-between gap-6 break-words text-center font-mono text-xs leading-normal md:mb-0 md:block md:max-w-16 md:gap-0 md:text-left ${ordinalId || archive.minting_in_progress ? 'text-theme' : 'text-muted-foreground'}`}
      >
        <div className="block md:hidden">
          <UpvoteButton archive={archive} />
        </div>
        {ordinalId && txid ? (
          <a
            href={getOrdinalExplorerUrl(ordinalId)}
            target="_blank"
            rel="noopener noreferrer"
            className="block truncate underline-offset-4 hover:underline md:whitespace-normal"
          >
            {txid}
          </a>
        ) : (
          <div
            className={`truncate md:whitespace-normal ${cn({ 'opacity-50': !archive.minting_in_progress, 'animate-pulse': archive.minting_in_progress })}`}
          >
            {new Array(64).fill(0).join('')}
          </div>
        )}
      </div>
    </div>
  )
}
