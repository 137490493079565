import { Navigate, useNavigate } from 'react-router-dom'
import { AppRoutes } from '@/routes'
import useUser from '@/hooks/useUser'
import { Authentication } from '@/components/Authentication'

export default function LoginPage() {
  const { loggedIn } = useUser()

  const navigate = useNavigate()

  if (loggedIn) return <Navigate to={AppRoutes.buildFeedRoute()} />

  return (
    <Authentication
      view="login"
      onSuccess={() => navigate(AppRoutes.SAVED)}
      className="mx-auto mt-10 w-full max-w-md flex-1 sm:mt-0"
    />
  )
}
