import { ArchiveCard } from '@/components/ArchiveCard'
import { api } from '@/lib/api'
import { LoaderCircleIcon } from 'lucide-react'
import { InView } from 'react-intersection-observer'

export default function BookmarksPage() {
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isLoading } =
    api.user.bookmarks.useInfiniteQuery(
      {},
      { getNextPageParam: ({ nextPage }) => nextPage },
    )

  const { data: btcPrice } = api.bitcoin.priceInfo.useQuery()

  const loading = isLoading || isFetchingNextPage
  const empty =
    !loading && !data?.pages.flatMap((page) => page.bookmarks).length

  const bookmarks = data?.pages.flatMap((page) => page.bookmarks) ?? []
  return (
    <div className="mx-auto mt-10 w-full max-w-screen-lg">
      <div className="flex items-center justify-between gap-4">
        <h1 className="text-2xl font-bold">Saved</h1>
      </div>
      <div className="mt-2 flex flex-col gap-2">
        {bookmarks
          .filter((b) => !!b.archive)
          .map((bookmark) => (
            <ArchiveCard
              key={bookmark._id}
              archive={bookmark.archive}
              btcPriceUsd={btcPrice?.price ?? 0}
            />
          ))}
      </div>
      {hasNextPage && (
        <InView
          as="div"
          onChange={(inView) => {
            if (inView) {
              fetchNextPage()
            }
          }}
        />
      )}
      {loading && (
        <div className="flex items-center justify-center">
          <LoaderCircleIcon className="size-6 animate-spin" />
        </div>
      )}
      {empty && (
        <p className="text-muted-foreground">
          No saved archives yet. Save your favorite archives to easily find them
          later.
        </p>
      )}
    </div>
  )
}
