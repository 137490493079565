export const EMAILS = {
  MEMORY: 'memory@ark.page',
} as const

export const MAX_RAW_CONTENT_SIZE = 1024 * 1024 * 4 // 4mb
export const MAX_MEDIA_FILE_SIZE = 1024 * 400 // 400kb
export const MAX_PROFILE_IMAGE_SIZE = 1024 * 1024 * 10 // 10mb
/**
 * Routes are defined in the backend so that we can build server-side metatags.
 */
export const ROUTES = {
  HOME: '/',
  NEW_ARCHIVE: '/new',
  /** @deprecated use `buildArchiveRoute` instead */
  ARCHIVE: '/archive',
  /** @deprecated */
  BROWSE: '/browse',
  FEED: '/feed',
  ADMIN: '/admin/:adminSecret',
  ABOUT: '/about',
  DIGGING_DEEPER: '/digging-deeper',
  STATS: '/stats',
  PRIVACY_POLICY: '/privacy-policy',
  DOWNLOADS: '/downloads',
  LOGIN: '/login',
  SIGNUP: '/signup',
  SAVED: '/saved',
  WALLET: '/wallet',
  /** @deprecated use `buildProfileRoute` instead */
  PROFILE: '/profile/:username',
  NEW_PROFILE: '/profile/new',
} as const

// Keep this here so we can use it in the frontend

// ORD supported extensions for reference:
// apng asc avif bin binpb cbor css flac gif glb gltf html jpg js json md mp3 mp4 otf pdf png py stl svg ttf txt wav webm webp woff woff2 yaml

export const SUPPORTED_MEDIA_EXTENSIONS = [
  'jpg',
  'jpeg',
  'png',
  'webp',
  'avif',
  'gif',
  'svg',
  'pdf',
]

export const SUPPORTED_MEDIA_MIME_TYPES = [
  'image/jpeg',
  'image/png',
  'image/webp',
  'image/avif',
  'image/gif',
  'image/svg+xml',
  'application/pdf',
]

export const MIME_TYPES_TO_FILE_EXTENSIONS: Record<
  (typeof SUPPORTED_MEDIA_MIME_TYPES)[number],
  (typeof SUPPORTED_MEDIA_EXTENSIONS)[number]
> = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/webp': 'webp',
  'image/avif': 'avif',
  'image/gif': 'gif',
  'image/svg+xml': 'svg',
  'application/pdf': 'pdf',
}
