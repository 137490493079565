import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'
import { toast } from '@/components/ui/use-toast'
import useUser from '@/hooks/useUser'
import { api } from '@/lib/api'
import { IArchive, IComment, ICommentVote } from '@/types'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  LoaderCircleIcon,
  MessageSquareIcon,
  MoreVerticalIcon,
  PencilIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
  Trash2Icon,
} from 'lucide-react'
import { useState } from 'react'
import { formatDistanceToNowStrict } from 'date-fns'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { useAnalytics } from '@/hooks/useAnalytics'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { formatDateWithTime, compactNumber } from '@/lib/formatting'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { InView } from 'react-intersection-observer'
import { cn } from '@/lib/utils'
import { convertLinksToAnchors } from '@/lib/text'
import { Badge } from '@/components/ui/badge'
import { Link } from 'react-router-dom'
import { AppRoutes } from '@/routes'

const MAX_REPLIES_INITIAL = 3

interface Props {
  archive: IArchive
}

export function Comments(props: Props) {
  const { archive } = props

  const { loggedIn, user } = useUser()

  const {
    data: comments,
    hasNextPage,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    refetch: refetchComments,
  } = api.comments.get.useInfiniteQuery(
    { url: archive.url },
    { getNextPageParam: (lastPage) => lastPage.nextCursor },
  )

  const { refetch: refetchCount } = api.comments.count.useQuery({
    url: archive.url,
  })

  const { data: userVotes, refetch: refetchUserVotes } =
    api.user.commentVotes.useQuery(
      { archiveUrl: archive.url },
      { enabled: loggedIn },
    )

  const loading = isLoading || isFetchingNextPage

  return (
    <div className="w-full space-y-8">
      <CommentForm archive={archive} refetchCount={refetchCount} />
      <div className="space-y-6">
        {comments?.pages
          .flatMap((page) => page.comments)
          .map((comment) => (
            <Comment
              key={comment._id}
              archive={archive}
              comment={comment}
              userPubkey={user?.pubKey}
              userVotes={userVotes}
              refetchComments={refetchComments}
              refetchCount={refetchCount}
              refetchUserVotes={refetchUserVotes}
            />
          ))}

        {hasNextPage && (
          <InView
            as="div"
            onChange={(inView) => {
              inView && fetchNextPage()
            }}
          />
        )}
        {loading && (
          <div className="mt-2">
            <LoaderCircleIcon className="size-6 animate-spin" />
          </div>
        )}
      </div>
    </div>
  )
}

interface CommentFormProps {
  archive: IArchive
  parentId?: string
  comment?: IComment
  refetchCount: () => void
  onSuccess?: () => void
}

function CommentForm(props: CommentFormProps) {
  const { archive, parentId, comment, refetchCount, onSuccess } = props

  const [content, setContent] = useState(comment?.content ?? '')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { user } = useUser()

  const analytics = useAnalytics()

  const utils = api.useUtils()

  const saveCommentMutation = api.comments.save.useMutation({
    onSuccess: async (savedComment) => {
      // Update the comments cache but don't refetch.
      // Refetching will cause the comments to be reordered and is a jarring
      // experience for the user.

      // Regardless of the final sort order that comes back from the server:
      // 1. New comments should be at the top of the list
      // 2. Edited comments should be updated in place

      utils.comments.get.setInfiniteData(
        { url: savedComment.archive_id, parentId: savedComment.parent_id },
        (d) => {
          if (!d) return undefined

          // Must create a deep copy of the data first
          const data = JSON.parse(JSON.stringify(d)) as typeof d

          if (comment) {
            // Edit the existing comment in place
            const pageIndex = data.pages.findIndex((page) =>
              page.comments.some((c) => c._id === comment._id),
            )

            if (pageIndex < 0) return data
            const commentIndex = data.pages[pageIndex].comments.findIndex(
              (c) => c._id === comment._id,
            )
            if (commentIndex < 0) return data

            data.pages[pageIndex].comments[commentIndex] = { ...savedComment }
          } else {
            // Add the new comment to the top of the list
            if (!data.pages[0].comments.length) {
              data.pages[0].comments = [{ ...savedComment }]
            } else {
              data.pages[0].comments = [
                { ...savedComment },
                ...data.pages[0].comments,
              ]
            }
          }

          return data
        },
      )

      analytics.sendEvent('comment_posted', { url: archive.url })

      setContent('')
      toast({ title: 'Comment Saved' })

      onSuccess?.()

      refetchCount()
    },
    onError: (e) => {
      toast({
        title: 'Error Commenting',
        description: e.message,
        variant: 'destructive',
      })
    },
  })

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault()

    try {
      if (!user) throw Error('Must be logged in to comment')

      if (!content.trim()) throw Error('Comment required')

      setIsSubmitting(true)

      await saveCommentMutation.mutateAsync({
        content,
        url: archive.url,
        parentId,
        author: user.paymail.split('@')[0],
        authorImage: user.image,
        id: comment?._id,
      })
    } catch (e) {
      console.error('Failed to submit comment:', e)

      toast({
        title: 'Error Commenting',
        description: e instanceof Error ? e.message : undefined,
        variant: 'destructive',
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  if (!user) return null

  return (
    <form
      onSubmit={handleSubmit}
      className={`space-y-4 ${cn({ 'ml-8': !!parentId })}`}
    >
      <Textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder={parentId ? 'Reply to comment...' : 'Write a comment...'}
        autoFocus={!!parentId}
        rows={2}
      />
      <Button type="submit" disabled={isSubmitting}>
        {isSubmitting
          ? 'Saving...'
          : comment
            ? 'Update'
            : parentId
              ? 'Reply'
              : 'Comment'}
      </Button>
    </form>
  )
}

interface CommentProps {
  comment: IComment
  archive: IArchive
  userPubkey: string | undefined
  userVotes: ICommentVote[] | undefined
  refetchComments: () => void
  refetchCount: () => void
  refetchUserVotes: () => void
}

function Comment(props: CommentProps) {
  const {
    archive,
    comment,
    userPubkey,
    userVotes,
    refetchUserVotes,
    refetchComments,
    refetchCount,
  } = props

  const [isReplying, setIsReplying] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const {
    data: repliesData,
    isLoading,
    refetch: refetchReplies,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = api.comments.get.useInfiniteQuery(
    { url: comment.archive_id, parentId: comment._id },
    { getNextPageParam: (lastPage) => lastPage.nextCursor },
  )

  const replies = repliesData?.pages.flatMap((page) => page.comments)
  const hasReplies = typeof replies !== 'undefined' && replies.length > 0
  const isOwnComment = comment.user_pubkey === userPubkey

  const userVote = userVotes?.find((vote) =>
    typeof vote.comment_id === 'string'
      ? vote.comment_id === comment._id
      : vote.comment_id._id === comment._id,
  )

  const deleteMutation = api.comments.delete.useMutation({
    onSuccess: async () => {
      toast({ title: 'Comment Deleted' })

      await refetchReplies()
      refetchComments()
      refetchCount()
    },
  })

  const utils = api.useUtils()

  const voteMutation = api.comments.vote.useMutation({
    onSuccess: async (updatedComment) => {
      toast({ title: 'Vote Saved' })

      refetchUserVotes()

      // Update the comment's upvotes in the cache but don't refetch.
      // Refetching will cause the comments to be reordered and is a jarring
      // experience for the user.
      utils.comments.get.setInfiniteData(
        { url: comment.archive_id, parentId: comment.parent_id },
        (data) => {
          if (!data) return undefined

          const pageIndex = data.pages.findIndex((page) =>
            page.comments.some(({ _id }) => _id === comment._id),
          )

          data.pages[pageIndex].comments = data.pages[pageIndex].comments.map(
            (existingComment) => {
              if (existingComment._id === comment._id) {
                return { ...existingComment, upvotes: updatedComment.upvotes }
              }
              return existingComment
            },
          )

          return data
        },
      )
    },
  })

  const vote = (vote: 'up' | 'down') => {
    if (!userPubkey) {
      return toast({ title: 'Must be logged in to vote' })
    }

    voteMutation.mutate({ id: comment._id, vote })
  }

  const loading = isLoading || isFetchingNextPage

  const showReplies =
    hasReplies && (replies.length <= MAX_REPLIES_INITIAL || isExpanded)

  const authorProfile =
    comment.user_pubkey && typeof comment.user_pubkey === 'object'
      ? comment.user_pubkey
      : undefined

  return (
    <div className="space-y-4">
      <div className="flex gap-4">
        <Link
          to={
            authorProfile
              ? AppRoutes.buildProfileRoute(authorProfile.username)
              : '#'
          }
        >
          <Avatar className="h-10 w-10">
            <AvatarImage
              src={authorProfile?.image}
              alt={authorProfile?.username}
              className="object-cover"
            />
            <AvatarFallback>
              {authorProfile?.username[0].toUpperCase()}
            </AvatarFallback>
          </Avatar>
        </Link>

        <div className="flex-1 space-y-2">
          <div className="flex items-start justify-between">
            <div className="flex items-center">
              {comment.user_pubkey === archive.user_pubkey ? (
                <Badge size="default">{authorProfile?.username}</Badge>
              ) : authorProfile ? (
                <Link
                  to={AppRoutes.buildProfileRoute(authorProfile.username)}
                  className="text-sm font-medium"
                >
                  {authorProfile.name}{' '}
                  <span className="text-muted-foreground font-normal">
                    @{authorProfile.username}
                  </span>
                </Link>
              ) : (
                <p className="text-sm font-medium">Anonymous</p>
              )}
              <div className="mx-1.5">·</div>
              <div className="text-muted-foreground text-xs">
                <Tooltip>
                  <TooltipTrigger>
                    {formatDistanceToNowStrict(new Date(comment.created_at), {
                      addSuffix: true,
                    })}
                    {/* {comment.created_at !== comment.updated_at && ' (edited)'} */}
                  </TooltipTrigger>
                  <TooltipContent side="right">
                    <div>
                      {formatDateWithTime(+new Date(comment.created_at))}
                    </div>
                  </TooltipContent>
                </Tooltip>
              </div>
            </div>
          </div>
          {isEditing ? (
            <CommentForm
              archive={archive}
              comment={comment}
              refetchCount={refetchCount}
              onSuccess={() => setIsEditing(false)}
            />
          ) : (
            <p className="whitespace-pre-line text-sm">
              {convertLinksToAnchors(comment.content)}
            </p>
          )}
          <div className="flex items-center gap-5">
            <div className="flex items-center gap-2">
              <button
                onClick={() => vote('up')}
                className={`flex items-center gap-2 text-sm ${cn({
                  'text-theme': userVote?.vote === 'up',
                })}`}
                disabled={isOwnComment}
              >
                <ThumbsUpIcon className="size-4" />{' '}
                {(comment.upvotes ?? 0) > 0 && compactNumber(comment.upvotes)}
              </button>
              <button
                onClick={() => vote('down')}
                disabled={isOwnComment}
                className={cn({ 'text-theme': userVote?.vote === 'down' })}
              >
                <ThumbsDownIcon className="size-4" />
              </button>
            </div>
            {!comment.parent_id && (
              <Button
                variant="link"
                size="sm"
                className="flex items-center gap-2 p-0 hover:bg-transparent"
                onClick={() =>
                  !userPubkey
                    ? toast({ title: 'Must be logged in to reply' })
                    : setIsReplying(!isReplying)
                }
              >
                <MessageSquareIcon className="h-4 w-4" />
                {isReplying ? 'Hide Reply' : 'Reply'}
              </Button>
            )}
          </div>
          {hasReplies && replies.length > MAX_REPLIES_INITIAL && (
            <Button
              variant="secondary"
              className="text-muted-foreground w-full text-xs"
              size="sm"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {compactNumber(replies.length)}{' '}
              {replies.length === 1 ? 'reply' : 'replies'}
              {isExpanded ? (
                <ChevronUpIcon className="size-5" />
              ) : (
                <ChevronDownIcon className="size-5" />
              )}
            </Button>
          )}
        </div>
        {isOwnComment && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                <MoreVerticalIcon className="h-4 w-4" />
                <span className="sr-only">Open menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-32">
              <DropdownMenuItem
                onClick={() => {
                  setIsEditing(true)
                }}
              >
                <PencilIcon className="mr-2 h-4 w-4" />
                Edit
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => deleteMutation.mutate({ id: comment._id })}
                className="text-destructive focus:text-destructive"
              >
                <Trash2Icon className="mr-2 h-4 w-4" />
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>

      {isReplying && (
        <div className="ml-6">
          <CommentForm
            parentId={comment._id}
            archive={archive}
            refetchCount={refetchCount}
            onSuccess={() => {
              setIsReplying(false)
              setIsExpanded(true)
            }}
          />
        </div>
      )}

      {showReplies && (
        <div className="ml-6 space-y-4 border-l-2 pl-4">
          {replies.map((reply) => (
            <Comment
              key={reply._id}
              comment={reply}
              archive={archive}
              refetchComments={() => {
                refetchComments()
                refetchReplies()
              }}
              refetchCount={refetchCount}
              refetchUserVotes={refetchUserVotes}
              userPubkey={userPubkey}
              userVotes={userVotes}
            />
          ))}
          {hasNextPage && (
            <InView
              as="div"
              onChange={(inView) => {
                inView && fetchNextPage()
              }}
            />
          )}
          {loading && (
            <div className="mt-2">
              <LoaderCircleIcon className="size-6 animate-spin" />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
