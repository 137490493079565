import { useToast } from '@/components/ui/use-toast'
import { Wallet } from '@canonicxyz/wallet-sdk'
import { atom, useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

const KEYS = {
  TOKEN: 'ark_token',
}

const tokenAtom = atomWithStorage<string | null>(KEYS.TOKEN, null)

const userAtom = atom<NonNullable<
  Awaited<ReturnType<typeof Wallet.getUser>>
> | null>(null)
const loadingAtom = atom(true)

export default function useUser() {
  const [user, setUser] = useAtom(userAtom)
  const [loading, setLoading] = useAtom(loadingAtom)
  const [token, setToken] = useAtom(tokenAtom)

  const toast = useToast()

  const loggedIn = !!token

  const setAuthToken = (jwt: string) => setToken(jwt)
  const clearAuthToken = () => setToken(null)

  const fetchUser = async () => {
    if (!Wallet.isLoggedIn() || !loggedIn) {
      setLoading(false)
      return setUser(null)
    }

    setLoading(true)

    try {
      const user = await Wallet.getUser()

      if (user) {
        user.image = `${user.image}?buster=${+new Date()}`
        setUser(user)
      }
    } catch (err) {
      toast.toast({
        title: 'Error getting user',
        description: err instanceof Error && err.message,
        variant: 'destructive',
      })
    }

    setLoading(false)
  }

  const logout = async () => {
    clearAuthToken()
    Wallet.logout()
    await fetchUser()
  }

  return {
    user,
    fetchUser,
    loading,
    logout,
    setAuthToken,
    clearAuthToken,
    token,
    loggedIn,
  }
}

export const getAuthToken = () => {
  const raw = localStorage.getItem(KEYS.TOKEN)
  if (!raw) return null

  try {
    // Legacy format saved in localStorage is slightly different so this could fail
    return JSON.parse(raw) as string
  } catch {
    return raw
  }
}
