import { Routes as ReactRouterRoutes, Route } from 'react-router-dom'
import { lazy } from 'react'
import { Layout } from './Layout'
import { FEED_SEARCH_PARAMS, FEED_VIEWS } from './pages/Feed/FeedPage'
import { ProtectedLayout } from './ProtectedLayout'
import { WALLET_SEARCH_PARAMS, WALLET_TABS } from './pages/WalletPage'
import { ROUTES } from 'backend/src/lib/constants'
import { NotFoundPage } from './pages/NotFoundPage'

// Don't lazy load the homepage for best initial UX
import HomePage from './pages/HomePage'
import {
  PROFILE_SEARCH_PARAMS,
  PROFILE_TABS,
} from './pages/Profile/ProfilePage'

type WalletTab = (typeof WALLET_TABS)[keyof typeof WALLET_TABS]
type FeedView = (typeof FEED_VIEWS)[keyof typeof FEED_VIEWS]
type ProfileTab = (typeof PROFILE_TABS)[keyof typeof PROFILE_TABS]
// eslint-disable-next-line react-refresh/only-export-components
export const AppRoutes = {
  ...ROUTES,
  buildArchiveRoute: (url: string) =>
    `${ROUTES.ARCHIVE}?url=${encodeURIComponent(url)}`,
  buildWalletRoute: (tab?: WalletTab) =>
    `${ROUTES.WALLET}${tab ? `?${WALLET_SEARCH_PARAMS.TAB}=${tab}` : ''}`,
  buildFeedRoute: (view?: FeedView) =>
    `${ROUTES.FEED}${view ? `?${FEED_SEARCH_PARAMS.VIEW}=${view}` : ''}`,
  buildProfileRoute: (username: string, tab?: ProfileTab) =>
    `${ROUTES.PROFILE.replace(':username', username)}${
      tab ? `?${PROFILE_SEARCH_PARAMS.TAB}=${tab}` : ''
    }`,
}

const ArchivePage = lazy(() => import('./pages/Archive/ArchivePage'))
const FeedPage = lazy(() => import('./pages/Feed/FeedPage'))
const AdminPage = lazy(() => import('./pages/Archive/Admin/AdminPage'))
const AboutPage = lazy(() => import('./pages/About/AboutPage'))
const StatsPage = lazy(() => import('./pages/StatsPage'))
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicyPage'))
const DownloadsPage = lazy(() => import('./pages/DownloadsPage'))
const LoginPage = lazy(() => import('./pages/LoginPage'))
const BookmarksPage = lazy(() => import('./pages/BookmarksPage'))
const SignupPage = lazy(() => import('./pages/SignupPage'))
const WalletPage = lazy(() => import('./pages/WalletPage'))
const DigDeeperPage = lazy(() => import('./pages/About/DiggingDeeperPage'))
const NewArchivePage = lazy(() => import('./pages/NewArchivePage'))
const ProfilePage = lazy(() => import('./pages/Profile/ProfilePage'))
const NewProfilePage = lazy(() => import('./pages/Profile/NewProfilePage'))
export function Routes() {
  return (
    <ReactRouterRoutes>
      <Route element={<Layout />}>
        <Route path={ROUTES.HOME} element={<HomePage />} />
        <Route path={ROUTES.NEW_ARCHIVE} element={<NewArchivePage />} />
        <Route path={ROUTES.ARCHIVE} element={<ArchivePage />} />
        <Route path={ROUTES.BROWSE} element={<FeedPage />} />
        <Route path={ROUTES.FEED} element={<FeedPage />} />
        <Route path={ROUTES.ADMIN} element={<AdminPage />} />
        <Route path={ROUTES.ABOUT} element={<AboutPage />} />
        <Route path={ROUTES.DIGGING_DEEPER} element={<DigDeeperPage />} />
        <Route path={ROUTES.STATS} element={<StatsPage />} />
        <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
        <Route path={ROUTES.DOWNLOADS} element={<DownloadsPage />} />
        <Route path={ROUTES.LOGIN} element={<LoginPage />} />
        <Route path={ROUTES.SIGNUP} element={<SignupPage />} />
        <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
        <Route path={ROUTES.NEW_PROFILE} element={<NewProfilePage />} />

        <Route element={<ProtectedLayout />}>
          <Route path={ROUTES.SAVED} element={<BookmarksPage />} />
          <Route path={ROUTES.WALLET} element={<WalletPage />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </ReactRouterRoutes>
  )
}
