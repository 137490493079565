import { Button } from './ui/button'
import { Authentication } from './Authentication'
import { useState } from 'react'

interface Props {
  onContinue: () => void
}

export function ArchiveInterstitial(props: Props) {
  const { onContinue } = props
  const [showLogin, setShowLogin] = useState(false)

  return (
    <div>
      {showLogin && (
        <Authentication view="login" inline onSuccess={onContinue} hideHeader />
      )}

      <div className="mx-auto mt-6 flex w-full flex-col items-center gap-2">
        {!showLogin && (
          <Button
            variant="theme"
            onClick={() => setShowLogin(true)}
            className="w-full"
          >
            Login
          </Button>
        )}
        <Button
          variant={'outline'}
          onClick={onContinue}
          className="w-full pl-6"
        >
          Skip &rarr;
        </Button>
      </div>
    </div>
  )
}
