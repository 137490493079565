import { createTRPCReact } from '@trpc/react-query'
import type { AppRouter } from 'backend/src/routers/app.router'

/** Upload files for raw archives */
const uploadFiles = async (files: File[]) => {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('files', file)
  })

  const res = await fetch(`/api/files/upload`, {
    method: 'POST',
    body: formData,
  })

  if (!res.ok) {
    throw new Error('Failed to upload files')
  }

  const data = (await res.json()) as { urls: string[] }

  return data
}

const uploadProfileImage = async (file: File) => {
  const formData = new FormData()
  formData.set('file', file)

  const res = await fetch(`/api/files/upload/profile`, {
    method: 'POST',
    body: formData,
  })

  if (!res.ok) {
    throw new Error('Failed to upload profile image')
  }

  const data = (await res.json()) as { url: string }

  return data
}

/** TRPC React client */
export const api = createTRPCReact<AppRouter>()

export const apiExtra = {
  uploadFiles,
  uploadProfileImage,
}
