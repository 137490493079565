import { ArchiveInterstitial } from '@/components/ArchiveInterstitial'
import { MarkdownView } from '@/components/MarkdownView'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Textarea } from '@/components/ui/textarea'
import { toast } from '@/components/ui/use-toast'
import { useDebouncedInput } from '@/hooks/useDebouncedInput'
import useUser from '@/hooks/useUser'
import { api, apiExtra } from '@/lib/api'
import { formatCurrency, formatFileSize } from '@/lib/formatting'
import { cn } from '@/lib/utils'
import { AppRoutes } from '@/routes'
import { IArchive } from '@/types'
import {
  MAX_RAW_CONTENT_SIZE,
  MAX_MEDIA_FILE_SIZE,
  SUPPORTED_MEDIA_MIME_TYPES,
} from 'backend/src/lib/constants'
import { FileIcon, LoaderCircleIcon, XIcon } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

async function sha256(message: string): Promise<string> {
  // Encode the message as UTF-8
  const msgBuffer = new TextEncoder().encode(message)

  // Hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)

  // Convert ArrayBuffer to byte array
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  // Convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')

  return hashHex
}

type Format = NonNullable<IArchive['format']>

export default function NewArchivePage() {
  const [hash, setHash] = useState('')
  const [format, setFormat] = useState<Exclude<Format, 'media'>>('plain-text')
  const [media, setMedia] = useState<File[]>([])
  const [interruptedAction, setInterruptedAction] = useState<
    (() => () => void) | null
  >(null)
  const [showInterstitial, setShowInterstitial] = useState(false)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const { loggedIn } = useUser()

  const navigate = useNavigate()

  const {
    inputValue: title,
    debouncedValue: debouncedTitle,
    handleInputChange: handleTitleInputChange,
  } = useDebouncedInput({ delay: 500, initialValue: '' })

  const { inputValue: author, handleInputChange: handleAuthorInputChange } =
    useDebouncedInput({ delay: 500, initialValue: '' })

  const {
    inputValue: content,
    debouncedValue: debouncedContent,
    handleInputChange: handleContentInputChange,
  } = useDebouncedInput({ delay: 500, initialValue: '' })

  const debouncedSize = new Blob([debouncedContent]).size

  const { data: fees, isFetching: loadingFees } =
    api.bitcoin.estimateFeesRawData.useQuery(
      {
        title: debouncedTitle,
        size: debouncedSize,
        media: media.map((f) => f.size),
      },
      { enabled: !!debouncedContent || !!media.length },
    )

  const createRawMutation = api.archives.createRaw.useMutation({
    onSuccess: ({ url }) => {
      navigate(AppRoutes.buildArchiveRoute(url))
      toast({
        title: 'Archive Created',
        description: 'Your archive has been successfully created.',
      })
    },
    onError: (error) => {
      toast({
        title: 'Error Creating Archive',
        description: error.message,
        variant: 'destructive',
      })
    },
  })

  const rawContentTooLarge = new Blob([content]).size > MAX_RAW_CONTENT_SIZE

  useEffect(() => {
    const hashContent = async () => {
      if (!content) {
        setHash('')
        return
      }

      setHash(await sha256(content))
    }

    hashContent()
  }, [content])

  const [draggingFileUploadState, setDraggingFileUploadState] = useState<
    'idle' | 'valid' | 'invalid'
  >('idle')

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    const validDragging = Array.from(e.dataTransfer.items).some((item) =>
      SUPPORTED_MEDIA_MIME_TYPES.includes(item.type),
    )
    setDraggingFileUploadState(validDragging ? 'valid' : 'invalid')
  }

  const handleDragLeave = () => {
    setDraggingFileUploadState('idle')
  }

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files ?? [])

    const filesTooLarge = files.filter(
      (file) => file.size > MAX_MEDIA_FILE_SIZE,
    )
    const duplicateFiles = files.filter((file) =>
      media.some((f) => f.name === file.name),
    )

    const invalidFiles = [...filesTooLarge, ...duplicateFiles]

    const validFiles = files.filter(
      (file) =>
        SUPPORTED_MEDIA_MIME_TYPES.includes(file.type) &&
        !invalidFiles.includes(file),
    )
    setMedia((prev) => [...prev, ...validFiles])
    setDraggingFileUploadState('idle')

    if (invalidFiles.length) {
      toast({
        title: 'Some files could not be added',
        duration: 10_000,
        description: (
          <div>
            Check that the following files are less than{' '}
            {formatFileSize(MAX_MEDIA_FILE_SIZE)} and that they have not already
            been added.
            <ul className="mt-2 list-disc pl-6 italic">
              {invalidFiles.map((file) => (
                <li key={file.name}>
                  {file.name} ({formatFileSize(file.size)})
                </li>
              ))}
            </ul>
          </div>
        ),
      })
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const createArchive = async () => {
    if (rawContentTooLarge) {
      toast({
        title: 'Content Too Large',
        description: `Content size exceeds limit of ${formatFileSize(MAX_RAW_CONTENT_SIZE)}.`,
        variant: 'destructive',
      })
      return
    }

    if (!title) {
      toast({
        title: 'Title is required',
        variant: 'destructive',
      })
      return
    }

    if (!content && !media.length) {
      toast({
        title: 'Content or media is required',
        variant: 'destructive',
      })
      return
    }

    try {
      const { urls: mediaUrls } = await apiExtra.uploadFiles(media)

      createRawMutation.mutate({
        title,
        author,
        content,
        format,
        media: mediaUrls,
      })
    } catch (error) {
      toast({
        title: 'Error Creating Archive',
        description: error instanceof Error ? error.message : 'Unknown error',
        variant: 'destructive',
      })
    }
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (loggedIn) {
      await createArchive()
    } else {
      setInterruptedAction(() => createArchive)
      setShowInterstitial(true)
    }
  }

  return (
    <div
      className={`mx-auto mt-16 w-full flex-1 ${cn({
        'max-w-screen-xl': format === 'markdown',
        'max-w-screen-md': format !== 'markdown',
      })}`}
    >
      <h1 className="text-2xl font-bold">Create a New Ark</h1>
      <div className="mt-8 flex w-full flex-1 flex-col items-center gap-4 self-stretch md:flex-row">
        <form className="w-full" onSubmit={onSubmit}>
          <div className="grid gap-4">
            <Input
              placeholder="Title"
              value={title}
              onChange={handleTitleInputChange}
            />
            {!loggedIn && (
              <Input
                placeholder="Author (optional)"
                value={author}
                onChange={handleAuthorInputChange}
              />
            )}
            <Textarea
              placeholder="Content (optional)"
              value={content}
              rows={20}
              onChange={handleContentInputChange}
              className="whitespace-pre-wrap"
              style={{
                fontFamily: format === 'ascii-art' ? 'monospace' : undefined,
              }}
            />
            <div className="flex items-center justify-between sm:items-start">
              <p
                className={cn(
                  'text-muted-foreground text-sm',
                  rawContentTooLarge && 'text-destructive',
                )}
              >
                {formatFileSize(new Blob([content]).size)} /{' '}
                {formatFileSize(MAX_RAW_CONTENT_SIZE)}
              </p>
              <div className="flex items-center gap-2">
                <p className="text-muted-foreground whitespace-nowrap text-sm">
                  Text Format
                </p>
                <Select
                  value={format}
                  onValueChange={(value) =>
                    setFormat(value as Exclude<Format, 'media'>)
                  }
                >
                  <SelectTrigger className="w-56 max-w-28">
                    <SelectValue placeholder="Format" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="plain-text">Plain Text</SelectItem>
                    <SelectItem value="ascii-art">Ascii Art</SelectItem>
                    <SelectItem value="markdown">Markdown</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <h3 className="text-lg font-bold">Media</h3>
            <label
              htmlFor="media-input"
              className={cn(
                'border-border relative flex h-32 w-full cursor-pointer flex-col items-center justify-center rounded-md border-[1.5px] border-dashed transition-colors',
                {
                  'border-green-400': draggingFileUploadState === 'valid',
                  'border-red-400': draggingFileUploadState === 'invalid',
                },
              )}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
            >
              <p className="">
                Drag and drop or{' '}
                <span className="underline underline-offset-4">
                  click to select files
                </span>
              </p>
              <p className="text-muted-foreground mt-2 text-xs">
                Images and PDFs supported. Max file size:{' '}
                {formatFileSize(MAX_MEDIA_FILE_SIZE)}
              </p>
              <input
                type="file"
                accept={SUPPORTED_MEDIA_MIME_TYPES.join(',')}
                id="media-input"
                ref={fileInputRef}
                multiple
                className="absolute inset-0 h-full w-full cursor-pointer opacity-0"
                onChange={onFileChange}
              />
            </label>
            {media.map((file, index) => (
              <div
                key={index}
                className="relative flex items-center gap-4 rounded-md border p-3"
              >
                <div className="size-16 overflow-hidden rounded-md">
                  {file.type.startsWith('image/') ? (
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      className="h-full w-full object-cover"
                    />
                  ) : (
                    <div className="bg-muted text-muted-foreground flex h-full w-full flex-col items-center justify-center gap-1 rounded-md">
                      <FileIcon className="h-4 w-4" />
                      <p className="text-xs uppercase">
                        {file.type.split('/')[1].split('+')[0]}
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex-1">
                  <p className="font-medium">{file.name}</p>
                  <p className="text-muted-foreground text-sm">
                    {formatFileSize(file.size)}
                  </p>
                </div>
                <Button
                  variant="ghost"
                  size="icon"
                  type="button"
                  className="text-muted-foreground hover:text-destructive absolute right-2 top-2"
                  onClick={() => setMedia(media.filter((_, i) => i !== index))}
                >
                  <XIcon className="h-4 w-4" />
                </Button>
              </div>
            ))}

            <p className={'text-muted-foreground flex items-center text-sm'}>
              Estimated cost to save on chain:{' '}
              {!content && !media.length ? (
                formatCurrency(0)
              ) : loadingFees ? (
                <LoaderCircleIcon className="ml-2 h-4 w-4 animate-spin" />
              ) : (
                formatCurrency(fees?.premiumFeesUsd ?? 0)
              )}
            </p>
            <p className={'text-muted-foreground break-all text-sm'}>
              Digital fingerprint: {hash}
            </p>
          </div>
          <Button
            type="submit"
            className="mt-4 w-full"
            variant="theme"
            disabled={
              createRawMutation.isPending ||
              rawContentTooLarge ||
              !title ||
              (!content && !media.length)
            }
          >
            {createRawMutation.isPending ? 'Creating...' : 'Create'}
            {createRawMutation.isPending && (
              <LoaderCircleIcon className="ml-2 h-4 w-4 animate-spin" />
            )}
          </Button>
        </form>
        {format === 'markdown' && (
          <div className="flex w-full flex-col self-stretch [word-break:break-all]">
            <div className="text-muted-foreground mb-2 text-sm">Preview:</div>
            <MarkdownView
              className="sm:prose-base prose-sm border-border flex-1 rounded-md border p-4"
              content={content}
            />
          </div>
        )}
      </div>
      <Dialog
        open={showInterstitial}
        onOpenChange={(open) => {
          if (!open) {
            setInterruptedAction(null)
          }

          setShowInterstitial(open)
        }}
      >
        <DialogContent aria-describedby={undefined}>
          <ArchiveInterstitial
            onContinue={() => {
              setShowInterstitial(false)
              interruptedAction?.()
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}
